import axios from 'axios';

const state = {
  notes: null,
  note: null
};

const getters = {
  stateAssemblies: state => state.assemblies,
  stateAssembly: state => state.assembly,
};

const actions = {
  async createAssembly({dispatch}, assembly) {
    await axios.post('assemblies', assembly);
    await dispatch('getAssemblies');
    //console.log('assembly created:',assembly,assembly.assembly_Benennung)
    return assembly
  },
  async getAssemblies({commit}) {
    let {data} = await axios.get('assemblies');
    console.log('getAssemblies:',data)
    commit('setAssemblies', data);
  },
  async viewAssembly({commit}, id) {
    console.log('asssembly.js: viewAssembly:',id)

    let {data} = await axios.get(`assembly/${id}`);
    commit('setAssembly', data);
  },
  // eslint-disable-next-line no-empty-pattern
  async updateAssembly({}, assembly) {
    await axios.patch(`asembly/${assembly.id}`, assembly.form);
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteAssembly({}, id) {
    await axios.delete(`assembly/${id}`);
  }
};

const mutations = {
  setAssemblies(state, assemblies){
    state.assemblies = assemblies;
  },
  setAssembly(state, assembly){
    state.assembly = assembly;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};