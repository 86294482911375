import axios from 'axios';

const state = {
  rankings: null,
  ranking: null
};

const getters = {
  stateRankings: state => state.rankings,
  stateRanking: state => state.ranking,
};

const actions = {


  //liefert nur zu angemeldetem User
  async getRanking({commit}) {


    //const asString2 = new URLSearchParams(payload).toString();

    //const url = 'tracks?downsamplefaktor=3&tracks_serial=' + payload.get("tracks_serial")  //tut aber warum nicht mit Parameter...?
    const url = 'rankings'
    //const {data} = await axios.post(url)
    let {data} = await axios.get(url);
    console.log('--->received:',data)

    commit('setRankings', data);
  },





};

const mutations = {
  setRankings(state, rankings){
    state.rankings = rankings;
  },
  setRanking(state, ranking){
    state.ranking = ranking;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};