<template>
  <div>
    <section>

          <v-card>
      <v-container >
          <template v-for="(item, index_n) in virtualRows" v-bind:key="item">
            <v-row no-gutters>
                  <template v-for="(item4,name, i) in item" v-bind:key="item4">
                      <v-col order=i>
                        <v-card
                          :class="getText_ColorDashboar(i,index_n,item4)"
                          flat
                          tile
                        >
                          <span v-text="getFormatTextDashboardVirtuell(i,index_n,item4)"></span>
                        </v-card>
                      </v-col>
                </template>

            <v-responsive
              v-if="index_n === 2"
              :key="`width-${index_n}`"
              width="100%"
            ></v-responsive>

            </v-row>

          </template>
      </v-container>
  </v-card>

    <v-card>
      <v-container >
          <template v-for="(item, index_n) in DepotKursRows" v-bind:key="item">
            <v-row no-gutters>


              <template v-for="(item4,name, i) in item"  v-bind:key="item4">
                  <v-col order=i>
                    <v-card
                      :class="getText_ColorDashboar(i,index_n,item4)"
                      flat
                      tile
                    >
                      <span v-text="getFormatTextDashboard(i,index_n,item4)"></span>
                    </v-card>
                  </v-col>
            </template>

            <v-responsive
              v-if="index_n === 2"
              :key="`width-${index_n}`"
              width="100%"
            ></v-responsive>

            </v-row>
          </template>
      </v-container>
  </v-card>

    <v-row>
    <template v-for="(item,name,index) in Users" :key="item">
         <td>
           <v-checkbox v-model="search"  :value="getUser(index)"  :label="getUser(index)"></v-checkbox>
          </td>
    </template>


    </v-row>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="array_allStocksFilteredSchwellwert"
        :search="search"
        :items-per-page=40
        :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50, 100]
        }"
        density="compact"
        @click:row="handleClickActivty"
        class="elevation-1 text-caption"
      >



          <template v-slot:item.stocks_name="{ item }">

                    <span v-text="item.stocks_name.substring(0, 10)"></span>


          </template>

         <template v-slot:item.stock_delta1_prozent="{ item }">
          <div :class="getColor(item.stock_delta1_prozent)">
              <span v-text="Math.ceil(item.stock_delta1_prozent *100)/100 + '%'"></span>
          </div>
         </template>

         <template v-slot:item.stock_delta5_prozent="{ item }">
          <div :class="getColor(item.stock_delta5_prozent)">
              <span v-text="Math.ceil(item.stock_delta5_prozent *100)/100 + '%'"></span>
          </div>
         </template>

         <template v-slot:item.stock_delta20_prozent="{ item }">
          <div :class="getColor(item.stock_delta20_prozent)">
              <span v-text="Math.ceil(item.stock_delta20_prozent *100)/100 + '%'"></span>
          </div>
         </template>

         <template v-slot:item.stock_Aktuellerkurs_gesamt="{ item }">
              <span v-text="Math.ceil(item.stock_Aktuellerkurs_gesamt) + ' €'"></span>
        </template>

         <template v-slot:item.delta="{ item }">

          <div :class="getColor(item.delta)">
              <span v-text="Math.ceil(item.delta *100)/100 + '€'"></span>
          </div>

         </template>
         <template v-slot:item.stocks_Menge="{ item }">
          <div :class="getColor(item.stocks_Menge)">
              <span v-text="Math.ceil(item.stocks_Menge *100)/100"></span>
          </div>
         </template>


      </v-data-table>
    </v-card>

    </section>
  </div>
    <v-row>
          <v-text-field
            v-model="search"
            label="Search (Aktueller Kurs groeser als)"
            class="pa-4"
          ></v-text-field>

          <v-text-field
            v-model="SchwellwertAktuellerKurs"
            label="SchwellwertAktuellerKurs"
            class="pa-4"
          ></v-text-field>
    </v-row>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash'
export default {
  name: 'DashboardScreen',
  data() {
    return {
      form: {
        title: '',
        content: '',
      },
        array_allStocks: [],
        array_allAggragtes: [],
        array_allRohstoffe: [],
        virtualRows:[],
        DepotKursRows:[],
        Users:[],
        UsersCheckBoxStatus:['walter','sandra','elli','joe'],
        array_allKurseGesamt: [],
        array_allDelta: [],
        SchwellwertAktuellerKurs: 0,
      search: '',
      searchUser: '',
      groupBy: [{ key: 'user_iduser.user_username' }],
      headers: [
        {
          title: 'name',
          key: 'stocks_name',
          fixed: true,

        },
        { title: 'Delta T %',key: 'stock_delta1_prozent',align: 'end'},
        { title: 'Delta W %',key: 'stock_delta5_prozent',align: 'end'},
        { title: 'Delta M %',key: 'stock_delta20_prozent',align: 'end'},
        { title: 'Delta gesamt €',
            key: 'delta',
            filter: value => {
              if (!this.SchwellwertAktuellerKurs) return true

              return value > parseInt(this.SchwellwertAktuellerKurs)
            },

            align: 'end'},
        { title: 'Aktuell gesamt €',
            key: 'stock_Aktuellerkurs_gesamt',
            align: 'end'},
        { title: 'User', key: 'user_iduser.user_username',align: 'start'},
        { title: 'Kaufkurs', key: 'stocks_Kaufkurs',align: 'end'},
        { title: 'Aktuellerkurs', key: 'stock_Aktuellerkurs',align: 'end'},
        { title: 'Menge', key: 'stocks_Menge',align: 'end'},
      ],
        pagination: {
          rowsPerPage: -1
        },
        latestGoogleupdate :'',
        pagination_aggregates : {'sortBy': 'stock_username', 'descending': true, 'rowsPerPage': -1},
        headers_Aggregates: [
          {
            text: 'stock_username ',
            align: 'left',
            sortable: true,
            value: 'stock_username',
            filter: value => {
              console.log('Filtervalues:',this.Users)
              console.log('UsersCheckBoxStatus:',this.UsersCheckBoxStatus)
              //console.log('Zeilenvalue:',value)


              var trefferFlag = false

              this.UsersCheckBoxStatus.forEach(function(item){
                    //console.log('item',item)
                    if (item == value){
                      //console.log('Treffer')
                      trefferFlag = true
                    }
                  //console.log('item',item);
                })
              //return true

              return trefferFlag



            },
          },
          {
            text: 'stocks_name',
            align: 'left',
            sortable: true,
            value: 'stocks_name'
          },
          { text: 'Delta gesamt €', value: 'stock_Aktuellerkurs',align: 'right'},
          { text: 'Delta T %', value: 'stock_delta1_prozent',align: 'right'},
          { text: 'Delta W %', value: 'stock_delta5_prozent',align: 'right'},
          { text: 'Delta M %', value: 'stock_delta20_prozent',align: 'right'},


        ],
    };
  },
  created: function() {
    console.log('Bin in Dasboard')

  },
  mounted: function(){

      return this.getDataFromApi()

  },
  computed: {
   ...mapGetters({ stocks: 'stateStocks'}),
   // filtered array_allStocks; updates anytime `this.search` changes
      array_allStocksFilteredSchwellwert() {
      //console.log('arr',this.array_allStocks[0])
        return this.search
          ? this.array_allStocks[0].filter(d => d.stock_Aktuellerkurs_gesamt > this.SchwellwertAktuellerKurs )
          : this.array_allStocks[0]
      },

  },
  methods: {
    ...mapActions(['createNote']),
    async submit() {
      console.log('DashboardScreen: submit createNote')
      await this.createNote(this.form);
    },
    handleClickActivty (item,row) {
        //console.log('###handleClickActivty:',row.item.aktivitaeten_distance,item)
        console.log('go to analyzer with this stock :',row.item.idstocks, row.item.stocks_name)
        console.log('this.$router:',this.$router)

        this.$router.push({name: 'Analyzer', params: {idstocks: row.item.idstocks,stocks_name: row.item.stocks_name}})
    },
      getUser (index) {
          //console.log('getUser:',index)
          //console.log('Users:',Object.keys(this.Users),Object.keys(this.Users)[index])
          return Object.keys(this.Users)[index]

      },
    filterByColumn (value, query, item) {
        //console.log('customfilter:',value, query, this.SchwellwertAktuellerKurs)
        //console.log('aktuellerkurs',item.stock_Aktuellerkurs,)


      const columnValue = item.user_iduser.user_username
      const columnValueKurs = item.stock_Aktuellerkurs_gesamt

      //console.log('columnvalue',columnValue)
      //console.log('UsersCheckBoxStatus',this.UsersCheckBoxStatus)


      if ((columnValue.indexOf(query) !== -1) && (columnValueKurs > this.SchwellwertAktuellerKurs)){
       console.log('TREFFER:',columnValue)
       return true
      }
      else{
       return false
       }

    },
    getColor (calories) {
      //console.log('getcolor:',calories)
        if (calories < 0) return 'text-red-lighten-1'
        else return 'text-green-lighten-1'
      },
      getText_ColorDashboar (spalte,zeile,wert) {
        //console.log('getcolor typ:',spalte,zeile,wert)
        if (zeile == 1 && spalte > 0){
        //Es ist eine Zahl: auf 2 Stellen runden
            wert = Math.ceil(wert *100)/100
            if (wert < 0){
             return 'text-red-lighten-1 text-right text-caption'
             //return  'red--text text--lighten-1 text-right'
            }
            else{
             return 'text-green-lighten-1 text-right text-caption'
            }
        }

        if (zeile > 1 && spalte > 0){
        //Es ist eine Zahl: auf 2 Stellen runden
            wert = Math.ceil(wert *100)/100
            if (wert < 0){
             return  'text-red-lighten-1 text-right text-caption'
            }
            else{
             return 'text-green-lighten-1 text-right text-caption'
            }
        }

        if (zeile == 0 &&  typeof spalte === 'undefined'){
             return  'subtitle-1 text-center'
        }

        if (spalte == 0){
             return  'text-caption'
        }


        //return  'red--text text--lighten-1'

      },
      getFormatTextDashboard (spalte,zeile,wert) {
       //console.log('getFormatTextDashboard:',spalte,zeile,wert)
        var wertKeineStelle = 0

        if (zeile > 0 && spalte > 0){
        //Es ist eine Zahl: auf 2 Stellen runden
            wert = Math.ceil(wert *100)/100
            wertKeineStelle = Math.ceil(wert *1)/1
        }

        if (zeile == 0)  return wert
        else if (spalte == 0) return wert
        else if (zeile == 1) return wertKeineStelle + ' €'
        else if (zeile == 2) return wertKeineStelle + ' €'
        else return wert + ' %'
        //if (zeile == 0)  return wert + ' %'
        //else return wert
      },
      getFormatTextDashboardVirtuell (spalte,zeile,wert) {
       //console.log('getFormatTextDashboard:',spalte,zeile,wert)
        var wertKeineStelle = 0

        if (zeile > 0 && spalte > 0){
        //Es ist eine Zahl: auf 2 Stellen runden
            wert = Math.ceil(wert *100)/100
            wertKeineStelle = Math.ceil(wert *1)/1
        }

        if (zeile == 0)  return wert
        else if (spalte == 0) return wert
        else if (zeile == 1) return wertKeineStelle + ' €'
        else if (zeile == 2) return wertKeineStelle + ' €'
        else if (zeile == 3) return wertKeineStelle + ' €'
        else return wert + ' %'
        //if (zeile == 0)  return wert + ' %'
        //else return wert
      },
    getDataFromApi () {
        this.loading = true
        var obj = this

        //this.$store.dispatch('getStocks').then(()=>{
        this.$store.dispatch('postStocks').then(()=>{
        console.log("--Got some data--")
              obj.stocks.sort((a, b) => (a.stocks_name > b.stocks_name) ? 1 : -1)
              //console.log('##>>Sorted StockList',obj.stocks)

          var array_allStocks_temp = []
          var array_allAggragtes_temp =[]
          var array_allRohstoffe_temp =[]
         //deltas vorab berechnen, damit danach sortiert werden kann in dem table
          obj.stocks.forEach(function(element) {
            //console.log('#',element.stocks_name, element.stocks_GoogleId, element.user_iduser.user_username)

            if (( element.stocks_GoogleId.indexOf("ufo") ==-1)&&( element.stock_Aktuellerkurs_gesamt > obj.SchwellwertAktuellerKurs)){
              array_allStocks_temp.push(element)
            } else if(element.stocks_Handelsplatz.indexOf("virtuell") >= 0){
              array_allAggragtes_temp.push(element)
            } else if(element.stocks_Handelsplatz.indexOf("AktuellerKurs") >= 0){
              array_allAggragtes_temp.push(element)
            } else if(element.stocks_Handelsplatz.indexOf("Kaufkurs") >= 0){
              array_allAggragtes_temp.push(element)
            } else if(element.stocks_Handelsplatz.indexOf("realisiert") >= 0){
              array_allAggragtes_temp.push(element)
            }

            if(element.stocks_Handelsplatz.indexOf("Rohstoff") >= 0){
              array_allRohstoffe_temp.push(element)
            }



            //if ((element.stocks_name.indexOf("0_") ==-1)&&( element.stocks_GoogleId.indexOf("ufo") ==-1) && (element.stock_Aktuellerkurs_gesamt>0)){
            if ((element.stock_Aktuellerkurs_gesamt>0)){
              element.delta = Math.ceil((element.stock_Aktuellerkurs * element.stock_Umrechnungskurs - element.stocks_Kaufkurs) * element.stocks_Menge)
              //obj.mylabels.push(element.stocks_name)
              obj.array_allKurseGesamt.push(element.stock_Aktuellerkurs_gesamt)
              obj.array_allDelta.push(element.delta)
//                  obj.mydatasets[0].data.push(Math.ceil(element.stock_Aktuellerkurs_gesamt))
//                  obj.mydatasets[1].data.push(element.delta)
//
//                  obj.barChartData.data.labels.push(element.stocks_name)
//                  obj.barChartData.data.datasets[0].data.push(Math.ceil(element.stock_Aktuellerkurs_gesamt))
//                  obj.barChartData.data.datasets[1].data.push(element.delta)
//                  if(element.delta>0){
//                    obj.mydatasets[1].backgroundColor.push('rgba(75, 192, 192, 0.2)') //gruen
//                    obj.barChartData.data.datasets[1].backgroundColor.push('rgba(75, 192, 192, 0.2)') //gruen
//                    }
//                  else{
//                    obj.mydatasets[1].backgroundColor.push('rgba(255, 99, 132, 0.2)') //rot
//                    obj.barChartData.data.datasets[1].backgroundColor.push('rgba(255, 99, 132, 0.2)') //gruen
//                    }
            }
          })

            //console.log('Stocks ohne agg:',array_allStocks_temp)
            //obj.array_allStocks.push(data.items)
            obj.array_allStocks.push(array_allStocks_temp)
            obj.array_allAggragtes.push(array_allAggragtes_temp)

            //console.log('#',array_allAggragtes_temp)
            const Results = _.groupBy(array_allAggragtes_temp,'stocks_Handelsplatz')
            //console.log('#grouped',Results)

            //console.log('>>ROOOHHH>>>',array_allRohstoffe_temp)
            //const ResultsRohstoff = _.groupBy(array_allRohstoffe_temp,'stocks_Handelsplatz')
            //console.log('#grouped Rohstoff',ResultsRohstoff)

            //var RohstoffbyUsers = _.groupBy(array_allRohstoffe_temp, 'stock_username')

            const RohstoffbyUsers = _.groupBy(array_allRohstoffe_temp, 'user_iduser.user_username')
            //console.log('###RohstoffbyUsers',RohstoffbyUsers)


            obj.Users = _.groupBy(array_allAggragtes_temp, 'user_iduser.user_username')
            //console.log('Users',obj.Users)

            //einlesen der gueltigen User in der richtigen reihenfolge zur laufzeit
            //console.log ('aktuelle User zur laufzeit',this.Users)
            //console.log ('UsersCheckBoxStatus zur laufzeit',this.UsersCheckBoxStatus)
            obj.UsersCheckBoxStatus = Object.keys(obj.Users)
            //console.log ('danach UsersCheckBoxStatus zur laufzeit',this.UsersCheckBoxStatus)

            //aus 3 virtuellen Reihen eine aufbauen mit 3 Spalten
            //const virtuellRows =[]
            var row_deltaGesamt = {'Typ':'Gesamt'}
            var row_deltaGesamt_NurRohstoffe = {'Typ':'Rohst.'}
            var row_deltaGesamt_OhneRohstoffe = {'Typ':'OhneRo.'}
            var row_deltaT = { 'Typ':'Tag'}
            var row_deltaW = { 'Typ':'Woche'}
            var row_deltaM = { 'Typ':'Monat'}
            var row_Users = ['Virtuell'] //erste spalte keine Ueberschrift


            for (var u in obj.Users){
                //console.log('--User --->u',u)
                const row_header =  { text: u, sortable: false }
                obj.headers_Aggregates.push(row_header)

                var RohstofvirtuellgesamtProUser = 0

                for (const r in Results.virtuell){

                    //const row = {typ: 'virtuell', 'Faller':121, 'Maier':23, 'Mueller':-254}
                    var tmpString = Results.virtuell[r].user_iduser.user_username
                    //console.log('r',r,tmpString, Results.virtuell[r])

                    if (tmpString.indexOf(u) >= 0  ){
                        row_deltaGesamt[u] = Results.virtuell[r].stock_Aktuellerkurs
                        row_Users.push(tmpString)

                        if (!RohstoffbyUsers[u]){
                                RohstofvirtuellgesamtProUser = 0
                        }
                        else{
                            //RohstofkursgesamtProUser = RohstoffbyUsers[u][0].stock_Aktuellerkurs_gesamt
                            RohstoffbyUsers[u].forEach((x)=>{
                                if (!RohstofvirtuellgesamtProUser){
                                    RohstofvirtuellgesamtProUser = 0
                                    }
                                //console.log('x,i,gesamt',x,i,x.stock_Aktuellerkurs_gesamt,RohstofvirtuellgesamtProUser)
                                RohstofvirtuellgesamtProUser = RohstofvirtuellgesamtProUser + x.delta
                                //console.log('user,RohstofvirtuellgesamtProUser bei Joe passt es',u, RohstofvirtuellgesamtProUser)
                            })
                        }



                        //console.log('row delta gesamt',row_deltaGesamt)
                        row_deltaGesamt_NurRohstoffe[u] = RohstofvirtuellgesamtProUser
                        row_deltaT[u] = Results.virtuell[r].stock_delta1_prozent
                        row_deltaW[u] = Results.virtuell[r].stock_delta5_prozent
                        row_deltaM[u] = Results.virtuell[r].stock_delta20_prozent
                        //console.log('row row_virtuell_deltaT',row_deltaT)
                       }
                }
            }

                    //jetzt noch die virtuelle Depotwerte ohne Rostoffe fuer alle User
            for (u in this.Users){
                row_deltaGesamt_OhneRohstoffe[u]=row_deltaGesamt[u] - row_deltaGesamt_NurRohstoffe[u]
            }


         obj.virtualRows.push(row_Users)
         obj.virtualRows.push(row_deltaGesamt)
         this.virtualRows.push(row_deltaGesamt_NurRohstoffe)
         this.virtualRows.push(row_deltaGesamt_OhneRohstoffe)
         obj.virtualRows.push(row_deltaT)
         obj.virtualRows.push(row_deltaW)
         obj.virtualRows.push(row_deltaM)

             //console.log('>>>virtual row',this.virtualRows)

            //aus 3 virtuellen Reihen eine aufbauen mit 3 Spalten
            //const virtuellRows =[]
            row_deltaGesamt = {'Typ':'Gesamt'}
            row_deltaT = { 'Typ':'Tag'}
            row_deltaW = { 'Typ':'Woche'}
            row_deltaM = { 'Typ':'Monat'}
            row_Users = ['Kurs'] //erste spalte keine Ueberschrift
            var row_depotOhneRohstoff = { 'Typ':'Depot'}

            for (var u2 in obj.Users){
                //console.log('u',u)
                const row_header =  { text: u2, sortable: false }
                this.headers_Aggregates.push(row_header)
                var RohstofkursgesamtProUser = 0
                for (const r in Results.AktuellerKurs){

                    //const row = {typ: 'virtuell', 'Faller':121, 'Maier':23, 'Mueller':-254}
                    var tmpString2 = Results.AktuellerKurs[r].user_iduser.user_username
                    //console.log('r',r,tmpString)

                    if (tmpString2.indexOf(u2) >= 0  ){
                        row_deltaGesamt[u2] = Results.AktuellerKurs[r].stock_Aktuellerkurs
                        row_Users.push(' ')


                        if (!RohstoffbyUsers[u2]){
                                RohstofkursgesamtProUser = 0
                        }
                        else{
                            //RohstofkursgesamtProUser = RohstoffbyUsers[u][0].stock_Aktuellerkurs_gesamt
                            RohstoffbyUsers[u2].forEach((x)=>{
                                if (!RohstofkursgesamtProUser){
                                    RohstofkursgesamtProUser = 0
                                    }
                                //console.log('x,i,gesamt',x,i,x.stock_Aktuellerkurs_gesamt,RohstofkursgesamtProUser)
                                RohstofkursgesamtProUser = RohstofkursgesamtProUser + x.stock_Aktuellerkurs_gesamt
                                //console.log('user,RohstofkursgesamtProUser',i,u2, RohstofkursgesamtProUser)
                            })
                        }
                        //console.log('u--',u,Results.AktuellerKurs[r].stock_username,RohstofkursgesamtProUser)
                        row_depotOhneRohstoff[u2] = Results.AktuellerKurs[r].stock_Aktuellerkurs - RohstofkursgesamtProUser
                        //console.log('row delta gesamt',row_deltaGesamt)
                        row_deltaT[u2] = Results.AktuellerKurs[r].stock_delta1_prozent
                        row_deltaW[u2] = Results.AktuellerKurs[r].stock_delta5_prozent
                        row_deltaM[u2] = Results.AktuellerKurs[r].stock_delta20_prozent
                        //console.log('row AktuellerKurs_deltaT',row_deltaT)
                       }

                }
            }

         obj.DepotKursRows.push(row_Users)
         obj.DepotKursRows.push(row_deltaGesamt)
         obj.DepotKursRows.push(row_depotOhneRohstoff)
         obj.DepotKursRows.push(row_deltaT)
         obj.DepotKursRows.push(row_deltaW)
         obj.DepotKursRows.push(row_deltaM)


         //console.log('DepotKursRows row',this.DepotKursRows)



          //draw chart
          //  obj.createChart('bar-chart', obj.barChartData)

            //GetLastGoogleupdate
            //obj.getLatestGoogleUpdate()


        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })


      },
  },
};
</script>