import axios from 'axios';

const state = {
  geoheatmaps: null,
  geoheatmap: null
};

const getters = {
  stateGeoheatmaps: state => state.geoheatmaps,
  stateGeoheatmap: state => state.geoheatmap,
};

const actions = {


  //liefert nur zu angemeldetem User
  async getGeoheatmap({commit},payload) {


    const asString2 = new URLSearchParams(payload).toString();

    //const url = 'tracks?downsamplefaktor=3&tracks_serial=' + payload.get("tracks_serial")  //tut aber warum nicht mit Parameter...?
    const url = 'geoheatmap?'+asString2
    const {data} = await axios.post(url)
    console.log('--->received:',data)

    commit('setGeoheatmaps', data);
  },





};

const mutations = {
  setGeoheatmaps(state, geoheatmaps){
    state.geoheatmaps = geoheatmaps;
  },
  setGeoheatmap(state, geoheatmap){
    state.geoheatmap = geoheatmap;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};