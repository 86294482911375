import axios from 'axios';

const state = {
  tracks: null,
  track: null
};

const getters = {
  stateTracks: state => state.tracks,
  stateTrack: state => state.track,
};

const actions = {

  //liefert nur zu angemeldetem User
  async getTracks({commit},payload) {

    const asString2 = new URLSearchParams(payload).toString();
    console.log('--Post:',asString2)

    //const url = 'tracks?downsamplefaktor=3&tracks_serial=' + payload.get("tracks_serial")  //tut aber warum nicht mit Parameter...?
    const url = 'tracks?'+asString2
    const {data} = await axios.post(url)

    commit('setTracks', data);
  },

//  async setTracks({commit}, id) {
//    console.log('setTrack:',id)
//
//    let {data} = await axios.get(`track/${id}`);
//    commit('setTrack', data);
//  },

  // eslint-disable-next-line no-empty-pattern
  async deleteTrack({}, id) {
    await axios.delete(`activity/${id}`);
  }
};

const mutations = {
  setTracks(state, tracks){
    state.tracks = tracks;
  },
  setTrack(state, track){
    state.track = track;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};