import axios from 'axios';

const state = {
  stocks: null,
  stock: null
};

const getters = {
  stateStocks: state => state.stocks,
  stateStock: state => state.stock,
};

const actions = {
  async createStock({dispatch}, stock) {
    await axios.post('savestock', stock);
    await dispatch('getStocks');
    return stock
  },
  //liefert nur zu angemeldetem User
  async getStocks({commit}) {
    console.log('<-<-<-<-<>>>stock.js: getStocks:')
    let {data} = await axios.get('stocks');
    commit('setStocks', data);
  },
  async createUrladungMitfiles({dispatch}, payload) {
    console.log('## in store >>createUrladungMitfiles',payload)
    console.log('das noch igbnorieren...',dispatch)
    await axios.post('urladungmitfiles', payload);
    //await dispatch('getBilder');
  },
  async createUrladung({dispatch}, payload) {
    console.log('## in store >>createUrladung',payload)
    console.log('das noch igbnorieren...',dispatch)
    let {data} = await axios.post('urladungmitID', payload);
    console.log('>>{data}',data)
    return data
  },
  //liefert zu allen Usern
  async postStocks({commit}) {
    console.log('<-<-<-<-<>>>stock.js: getStocks:')
    let {data} = await axios.post('stocks');
    commit('setStocks', data);
    },
  async viewStock({commit}, id) {
    console.log('stock.js: viewStock:',id)

    let {data} = await axios.get(`stock/${id}`);
    commit('setStock', data);
  },
  // eslint-disable-next-line no-empty-pattern
  async updateStock({dispatch}, stock) {
    //await axios.patch(`stock/${stock.id}`, stock.form);
    console.log('stock.js: updatestock:',stock.stocks_name,stock.user_iduser_id)
    await axios.post('updatestock', stock);
    await dispatch('getStocks');
    return stock
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteStock({}, id) {
    await axios.delete(`stock/${id}`);
  }
};

const mutations = {
  setStocks(state, stocks){
    state.stocks = stocks;
  },
  setStock(state, stock){
    state.stock = stock;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};