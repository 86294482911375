<template>

{{tracks[0]}}
{{tracks[1]["specific_TRAC_URL"]}}
{{tracks[2][1]}}

    <v-card
       color="white"
    >
      <div>
        <canvas id="line4TSS-chart"></canvas>
       </div>
    </v-card>

    <v-card
       color="white"
    >
      <div>
        <canvas id="steigungs-chart"></canvas>
       </div>
    </v-card>

  <v-form>
    <v-container>
      <v-row>

      <v-menu v-model="isMenuOpen" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-text-field
            label="abDatum"
            :model-value="computedabDatumFormattedDatefns"
            readonly
            v-bind="props"
          ></v-text-field>
        </template>
        <v-date-picker v-model="abDatum"></v-date-picker>
      </v-menu>

      <v-menu v-model="isMenuOpenBisDatum" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-text-field
            label="bisDatum"
            :model-value="computedbisDatumFormattedDatefns"
            readonly
            v-bind="props"
          ></v-text-field>
        </template>
        <v-date-picker v-model="bisDatum"></v-date-picker>
      </v-menu>

      </v-row>
    </v-container>
  </v-form>




<v-btn
  size="small"
  variant="flat"
  color= 'secondary'
  :href='"/"+tracks[1]["specific_TRAC_URL"]' target="_blank"
>
  Show Map
</v-btn>

<v-btn
  size="small"
  variant="flat"
  color= 'secondary'
  @click="updateMap"
>
  Update Map
</v-btn>


<iframe id="theFrame" :src='"/"+tracks[1]["specific_TRAC_URL"]' style="height:1000px;width:100%;" frameborder="0">
</iframe>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ref } from 'vue'
import { format } from 'date-fns'
import { Chart, registerables  } from "chart.js";
Chart.register(...registerables);
//import _ from 'lodash'

export default {
  name: 'TracsScreen',

    data: () => ({
      item: '',
      url2myMap:'/userdir/garmin/map_with_coordinates_and_line.html',
      abDatum: new Date('2023-11-16'),
      abDatumString: '',
      bisDatum: new Date(),
      bisDatumString: '',
      isMenuOpen: ref(false),
      isMenuOpenBisDatum: ref(false),
      activityID: 32,
              lineSteigungChartData : {
                type: 'bar',
                data: {
                  labels: ["January", "February", "March", "April", "May", "June", "July"],
                  datasets: [
                    { // one line graph
                      label: "Ø Speed (km/h)",
                      data: [0, 0, 1, 2, 67, 62, 27, 14],
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      yAxisID: "yaxisLinks",
                    },
                    { // another line graph
                      label: "Max FTP",
                      data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
                      backgroundColor: 'rgba(204, 0, 102, 0.2)',
                      fill:false,
                      yAxisID: "yaxisRechts",
                    }
                  ]
                },
                options: {
                  responsive: true,
                  lineTension: 1,
                  scales: {
                      yAxes: [{
                          stacked: false
                      }],

                        },


                }
              },
              line4TSSChartData : {
                type: 'line',
                data: {
                  labels: ["January", "February", "March", "April", "May", "June", "July"],
                  datasets: [
                    { // one line graph
                      label: "Ø Speed (km/h)",
                      data: [0, 0, 1, 2, 67, 62, 27, 14],
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      yAxisID: "yaxisLinks",
                    },
                    { // another line graph
                      label: "Max FTP",
                      data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                      fill:false,
                      yAxisID: "yaxisRechts",
                    }
                  ]
                },
                options: {
                  responsive: true,
                  lineTension: 1,
                  scales: {
                      yAxes: [{
                          stacked: false
                      }],

                        },


                }
              },
           line4TSSChart: null,
    }),
      computed: {
       ...mapGetters({ tracks: 'stateTracks'}),
       computedabDatumFormattedDatefns () {

            //tut let abDatumFormatiert = format(this.abDatum, 'EEEE, MMMM do yyyy')
            let abDatumFormatiert = format(this.abDatum, 'yyyy-MM-dd')
            //console.log('>>formatiert>>abdatum:',abDatumFormatiert)
            return abDatumFormatiert
       },
       computedbisDatumFormattedDatefns () {

            //tut let abDatumFormatiert = format(this.abDatum, 'EEEE, MMMM do yyyy')
            let bisDatumFormatiert = format(this.bisDatum, 'yyyy-MM-dd')
            //console.log('>>formatiert>>abdatum:',abDatumFormatiert)
            return bisDatumFormatiert
       },
      },
    mounted() {
        //Abdatum mit Gestern initialsieren
        const heute = new Date()
        const yesterday = new Date(heute)
        yesterday.setDate(yesterday.getDate() - 40)
        console.log('today:',heute )
        console.log('gestern:',yesterday )

        this.abDatum = yesterday

        var abDatumString = new Date(this.abDatum.getTime() - (this.abDatum.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
        var bisDatumString = new Date(this.bisDatum.getTime() - (this.bisDatum.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];

        const aktivitaten_activityId = this.$route.params.aktivitaten_activityId

        var obj = this

        if(aktivitaten_activityId)
        {
            console.log('Bin tracklist mounted...with Activityid:', aktivitaten_activityId )

            //asyncron url mit Parameter aufrufen
            //dann auf backend htm seite generieren im User Verzeichnis ablegen
            //und hierher den Link senden
            const TracForm = new FormData();
            TracForm.append('downsamplefaktor', 2);
            TracForm.append('tracks_serial', aktivitaten_activityId);
            TracForm.append('userdir', 'aha');



            //const downsamplefaktor = 2
            this.$store.dispatch('getTracks',TracForm).then(()=>{
                console.log("Got some data, now lets show something in this component",this.tracks)
                //refresh map
                document.getElementById('theFrame').src += '';

                //Daten fuer Chart aufbereiten

              //flush array
              while (this.line4TSSChartData.data.labels.length > 0) {
                  this.line4TSSChartData.data.labels.pop();
                }

              while (this.line4TSSChartData.data.datasets.length > 0) {
                  this.line4TSSChartData.data.datasets.pop();
                }


          //array allokieren
          //var farben =['rgba(255, 153, 80, 0.2)','rgba(128, 77, 0, 0.8)','rgba(50, 192, 50, 0.2)','rgba(75, 192, 192, 0.2)']
          var yAxisID =['yaxisLinks','yaxisRechts']
          var index3 = 0
          var index2 = 0

          index3 +=1


          //chart Daten aufbereiten
          var labelCounter = 0
          var tempHoehenlist = []
          var tempLabellist = []
          var Gesamtentfernung = 0

          var tempHoehenSteigunglist = []
          var tempHohenSteigungListLabel = []
          var PreviousRemainder = 1
          var PreviousHoehe = obj.tracks[2].hoehenprofil[0][2]
          var Hoehe = 0
          var Steigung = 0
          var Hoehendifferenz = 0


            console.log('obj.tracks[2]',obj.tracks[2].hoehenprofil)

          //_.forEach(obj.tracks[2].hoehenprofil,item3 => {
          while (labelCounter < obj.tracks[2].hoehenprofil.length){

                var item3 = obj.tracks[2].hoehenprofil[labelCounter]
                if (labelCounter > 0){
                    var vorhergehenderPunkt = obj.tracks[2].hoehenprofil[labelCounter - 1 ]
                    var entfernungMeter = this.distance(item3[0],item3[1],vorhergehenderPunkt[0],vorhergehenderPunkt[1],'K')
                    //console.log('Entfernung',entfernungMeter)
                    Gesamtentfernung = Gesamtentfernung + entfernungMeter
                    tempHoehenlist.push(item3[2])
                    tempLabellist.push(Gesamtentfernung.toFixed(2))

                    var Steigunscheibe = 1 //Steigunscheibe z.B. 10 bedeutet 100 Meter; 1 bedeutet 1000 m  / 1 km
                    var remainder =  (Gesamtentfernung * Steigunscheibe) % 1  //Steigunscahibe z.B. 10
                    //console.log('>>Gesamtentfernung:',Gesamtentfernung)
                    // console.log('-KM-remainders:',PreviousRemainder,remainder)
//km Scheiben sind zu grob auf 10 m gehen
//eigentlich die + hoehenmeter pro gpx punkt addieren, das durch den Weg teilen
                    Hoehe = obj.tracks[2].hoehenprofil[labelCounter][2]
                    //Höhenänderung / Strecke *100 ergibt die Steigung in Prozent.
                    //console.log('##############################')
                    //console.log('PreviousHoehe',PreviousHoehe)
                    //console.log('Hoehe',Hoehe)
                    //nur die positiven Hoehenmeter zaehlen?
                    if(Hoehe > PreviousHoehe ){
                        Hoehendifferenz = Hoehendifferenz + (Hoehe-PreviousHoehe)
                        //console.log('Höhendifferenz', Hoehendifferenz)
                        }

                    if (remainder < PreviousRemainder){
                            console.log('-KM-Gesamt:',Gesamtentfernung)

                            Steigung = (Hoehendifferenz/(1000/Steigunscheibe)*100).toFixed(1)
                            console.log('##############################')
                            console.log('Steigung',Steigung)

                            tempHoehenSteigunglist.push(Steigung)
                            tempHohenSteigungListLabel.push(Gesamtentfernung.toFixed(2))

                            Hoehendifferenz = 0
                            PreviousRemainder = 1


                            }
                    PreviousHoehe = Hoehe
                    PreviousRemainder = remainder

                    }
                //console.log('trackpoint',item3[2])

                labelCounter = labelCounter + 1
            }

              var neuesDataset = {}
              neuesDataset['label'] = 'hoehe'
              neuesDataset['data'] = tempHoehenlist  //so tut es und wenn man die datastes vorher nicht flusht
              neuesDataset['backgroundColor'] = 'rgba(204, 153, 0, 0.2)'
              neuesDataset['yAxisID'] = yAxisID[index3]
              neuesDataset['fill'] = 'false'
              neuesDataset['pointStyle'] = 'rect'
              neuesDataset['borderColor'] = 'rgba(20, 153, 0, 0.2)'

              obj.line4TSSChartData.data.datasets.push(neuesDataset)
              obj.line4TSSChartData.data.datasets[index2].type = 'line'
              obj.line4TSSChartData.data.datasets[index2].lineTension = 0.2
              obj.line4TSSChartData.data.labels = tempLabellist

            console.log('obj.line4TSSChartData',obj.line4TSSChartData)
            console.log('Gesamtentfernung',Gesamtentfernung)
            console.log('tempHoehenSteigunglist',tempHoehenSteigunglist)
                obj.createline4TSSChart("line4TSS-chart", obj.line4TSSChartData);


              //flush array
              while (this.lineSteigungChartData.data.labels.length > 0) {
                  this.lineSteigungChartData.data.labels.pop();
                }

              while (this.lineSteigungChartData.data.datasets.length > 0) {
                  this.lineSteigungChartData.data.datasets.pop();
                }


              neuesDataset = {}
              neuesDataset['label'] = 'Steigung'
              neuesDataset['data'] = tempHoehenSteigunglist  //so tut es und wenn man die datastes vorher nicht flusht
              neuesDataset['backgroundColor'] = 'rgba(204, 0, 102, 0.7)'
              neuesDataset['yAxisID'] = yAxisID[index3]
              neuesDataset['fill'] = 'false'
              neuesDataset['pointStyle'] = 'rect'
              neuesDataset['borderColor'] = 'rgba(177, 0, 132, 0.7)'

              obj.lineSteigungChartData.data.datasets.push(neuesDataset)
              obj.lineSteigungChartData.data.datasets[index2].type = 'bar'
              obj.lineSteigungChartData.data.datasets[index2].lineTension = 0.2
              obj.lineSteigungChartData.data.labels = tempHohenSteigungListLabel


                obj.createline4TSSChart("steigungs-chart", obj.lineSteigungChartData);

            }, error => {
                console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
            })
          }
          else{
            console.log('Aufruf ohne Aktivity ID mit ab und Bis Datum')
            const TracForm = new FormData();
            TracForm.append('downsamplefaktor', 3);
            TracForm.append('tracks_serial', '0');
            TracForm.append('abDatum', abDatumString);
            TracForm.append('bisDatum', bisDatumString);

            this.$store.dispatch('getTracks',TracForm).then(()=>{
                console.log("Got some data, now lets show something in this component",this.tracks)
                //refresh map
                document.getElementById('theFrame').src += '';

              }, error => {
                console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
              })

          }

    },
    updated() {
    // Fired every second, should always be true
    //console.log('updated DOM rerender')

    },

  methods: {
  ...mapActions(['getTracks']),
    updateMap() {
        //this.abDatum = new Date(this.abDatumString)
        console.log('update map with this abDatum:',this.abDatum)
        console.log('update map with this bisDatum:',this.bisDatum)
        var abDatumString = new Date(this.abDatum.getTime() - (this.abDatum.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
        var bisDatumString = new Date(this.bisDatum.getTime() - (this.bisDatum.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
        //this.$router.push({name: 'Tracks', params: {aktivitaten_activityId: row.item.aktivitaten_activityId}})
        console.log('Aufruf ohne Aktivity ID mit ab und Bis Datum')
            const TracForm = new FormData();
            TracForm.append('downsamplefaktor', 3);
            TracForm.append('tracks_serial', '0');
            TracForm.append('abDatum', abDatumString);
            TracForm.append('bisDatum', bisDatumString);

            this.$store.dispatch('getTracks',TracForm).then(()=>{
                console.log("Got some data, now lets show something in this component",this.tracks)
                //refresh map
                document.getElementById('theFrame').src += '';

              }, error => {
                console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
              })

    },
    //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    distance(lat1, lon1, lat2, lon2, unit) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1/180;
            var radlat2 = Math.PI * lat2/180;
            var theta = lon1-lon2;
            var radtheta = Math.PI * theta/180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180/Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit=="K") { dist = dist * 1.609344 }
            if (unit=="N") { dist = dist * 0.8684 }
            return dist;
        }
    },
    createline4TSSChart(chartId, chartData) {

        let chartStatus = Chart.getChart(chartId); // <canvas> id
        if (chartStatus != undefined) {
            chartStatus.destroy();
            }

      const ctx = document.getElementById(chartId);
      // Save reference
      this.line4TSSChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
    },




  }
}
</script>

