<template>

  <v-alert v-if="dialogUrladungAlert"
    text="Urladung nicht erfolgreich"
    title="Alert title"
    type="info"
    variant="tonal"
    closable
  ></v-alert>

          <v-card>

            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Your Stocks</h3>
                <div>Create new ones if you like...</div>
              </div>
            </v-card-title>

            <v-card-actions>
                <v-btn
                prepend-icon="mdi-plus-circle-outline"
                @click="createStockGUI('schalter')"> create new Stock</v-btn>

                <v-checkbox
                :model-value='zeigeStocksAllerUser'
                label="Zeige Stocks aller user"
                @click="showall('schalter')"></v-checkbox>

            </v-card-actions>
          </v-card>


    <v-alert v-model="alert" type="error" dismissible>
      Fehler beim Speichern.
    </v-alert>

    <v-list v-for="(item) in stocks" v-bind:key="item" >

    <div v-if="(users.iduser == 1 && item.user_iduser.iduser == 1 )||(users.iduser != 1)||(zeigeStocksAllerUser == true)">

        <v-card
        class="d-flex align-content-start"
        v-bind:color='getCardcolor(item.stocks_Menge)'
        >
               <v-text-field
               label="Name"
               v-model= "item.stocks_name"

               required
             ></v-text-field>
        </v-card>




    <v-row no-gutters>

                <v-text-field
                label="UserName"
                v-model="item.user_iduser.user_username"
                readonly
                ></v-text-field>



              <v-text-field
               label="Handelsplatz"
               hint="ETR"
               v-model="item.stocks_Handelsplatz"
               required
             ></v-text-field>

             <v-text-field
               label="GoogleID"
               hint='DTE'
               v-model="item.stocks_GoogleId"
               required
             ></v-text-field>

            <v-text-field
            label="Realisiert"
            v-model="item.stock_Realsiert"
            readonly
            ></v-text-field>
                            <v-select
                          label="Select"
                          v-model="item.stocks_Land"
                          :items="laender"
                            item-title="land"
                            item-value="wert"
                        ></v-select>


                      <v-text-field
                       hint='DE34542534254'
                       v-model="item.stocks_ISIN"
                       label="ISIN"
                     ></v-text-field>

    </v-row>
    <v-row no-gutters>


                      <v-text-field
                       hint='für Google table als Id notwendig'
                       v-model="item.idstocks"
                       label="ID stock"
                       readonly
                     ></v-text-field>

                      <v-text-field
                       label="Menge"
                       hint ="10.65"
                       v-model="item.stocks_Menge"
                     ></v-text-field>

                      <v-text-field
                       label="Kaufdatum"
                       hint ="2018-04-20"

                       v-model="item.stocks_Kaufdatum"
                     ></v-text-field>

                      <v-text-field
                       label="Kaufkurs"
                       hint ="145.65"
                       v-model="item.stocks_Kaufkurs"
                     ></v-text-field>

    </v-row>

    <v-row no-gutters>
                      <v-text-field
                       label="AktuellerKurs"
                       v-model="item.stock_Aktuellerkurs"
                     ></v-text-field>

                      <v-text-field
                       label="StopLoss"
                       v-model="item.stocks_stoploss"
                     ></v-text-field>

                      <v-text-field
                       label="SL Gültig bis"

                     ></v-text-field>

                          <v-text-field
                           label="UmrechnungsKurs"
                           v-model="item.stock_Umrechnungskurs"
                         ></v-text-field>

            </v-row>

            <v-row no-gutters>

               <v-btn v-if="(item.stocks_name.slice(0, 2) != '0_')&&(CreateModus==false)"
               @click.stop="dialog=true"
               @click="addStocks(item.idstocks,item.stocks_name)"
               prepend-icon="mdi-cart-plus"
               >buy</v-btn>

               <v-btn v-if="(item.stocks_name.slice(0, 2) != '0_')&&(CreateModus==false)"
               @click.stop="dialogSubtract=true"
               @click="subtractStocks(item)"
               prepend-icon="mdi-cart-minus"
               >sell</v-btn>

               <v-btn v-if="(item.stocks_name.slice(0, 2) != '0_')&&(CreateModus==false)"
               @click="updateStockOnDB(item)"
               prepend-icon="mdi-content-save"
               >save</v-btn>
               <v-btn v-if="CreateModus"
                 prepend-icon="mdi-plus-circle-outline"
                 @click="createStockOnDB(item)">create</v-btn>

               <v-btn v-if="(item.stocks_name.slice(0, 2) != '0_')"
                 prepend-icon="mdi-file-restore"
                 color="orange"
                 @click="urladungStockOnDB(item)"> Urladung</v-btn>

               <v-file-input v-if="(item.stocks_name.slice(0, 2) != '0_')"
                    label="Urladung File input"
                    variant="filled"
                    prepend-icon="mdi-file"
                    @change="onFileChange(item, $event)"
                  ></v-file-input>
               <v-btn v-if="(item.stocks_name.slice(0, 2) != '0_')"
                 prepend-icon="mdi-delete"
                 color="red"
                 @click="deleteStockOnDB(item)"> delete</v-btn>
            </v-row>

       </div>
    </v-list>

    <v-dialog
      v-model="dialogUrladungRunning"
      max-width="320"
      persistent
    >
      <v-list
        class="py-2"
        color="primary"
        elevation="12"
        rounded="lg"
      >
        <v-list-item
          prepend-icon="$vuetify-outline"
          title="Urladung..."
        >
          <template v-slot:prepend>
            <div class="pe-4">
              <v-icon color="primary" size="x-large"></v-icon>
            </div>
          </template>

          <template v-slot:append>
            <v-progress-circular
              color="primary"
              indeterminate="disable-shrink"
              size="16"
              width="2"
            ></v-progress-circular>
          </template>
        </v-list-item>
      </v-list>
    </v-dialog>


    <v-dialog v-model="dialog" max-width="500px">

      <v-card>
        <v-card-title>
          <span class="headline">Zukaufen von Anteilen</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>

                <v-text-field v-model="editedItem.menge" label="Menge" hint="1.2" ></v-text-field>


                <v-text-field v-model="editedItem.preis" label="Ver/Kauf-Preis PRO Stock" hint="(immer positiv) in Euro pro Stück" ></v-text-field>


                <v-text-field v-model="editedItem.provision" label="Provision (brauch ich die überhaupt?)" hint="meist bei Sparplan separat in Euro" ></v-text-field>

            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" @click="createTransaction(1,item)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSubtract" max-width="500px">

      <v-card>
        <v-card-title>
          <span class="headline">Verkaufen von Anteilen</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>

                <v-text-field v-model="editedItem.menge" label="Menge" hint="1.2" ></v-text-field>


                <v-text-field v-model="editedItem.preis" label="Ver/Kauf-Preis PRO Stock" hint="(immer positiv) in Euro pro Stück" ></v-text-field>


                <v-text-field v-model="editedItem.provision" label="Provision (brauch ich die überhaupt?)" hint="meist bei Sparplan separat in Euro" ></v-text-field>

            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" @click="createTransaction(2,item)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'StocksScreen',
  data() {
    return {
      form: {
        title: 'hase',
        content: '12',
      },
      zeigeStocksAllerUser: false,
      aktuellerUser: '',
      CreateModus: false,
      nameRules: [
          v => !!v || 'Name is required',
          v => v.length > 6 || 'Name must be more than 6 characters'
        ],
        laender: [
          { land: 'USA', wert: 'USA' },
          { land: 'Deutschland', wert: 'Deutschland' },
          { land: 'Welt', wert: 'Welt' },
          { land: 'China', wert: 'China' },
          { land: 'Japan', wert: 'Japan' },
          { land: 'Argentinien', wert: 'Argentinien' },
          { land: 'Kanada', wert: 'Kanada' },
          { land: 'Frankreich', wert: 'Frankreich' },
          { land: 'Schweiz', wert: 'Schweiz' },
          { land: 'Niederlande', wert: 'Niederlande' },
        ],
        dialog: false,
        dialogUrladungRunning: false,
        dialogUrladungAlert: false,
        dialogSubtract: false,
        editedIndex: -1,
        editedItem: {
          typ: 2,
          key: -1,
          preis: 0,
          datum: 0,
          menge: 0,
          beschreibung: '',
          stocks_idstocks: 0,
          indexListe: -1,
          indextable: -1,
          aenderungskennzeichen: -1,
          provision: 0
        },
        alert: false,
        uploadedfile:'',
    };
  },
  mounted: function() {
    console.log('Bin in Stocks mounted')
    //this.aktuellerUser = this.$store.getters.stateUser.user_username
    var obj = this
    this.$store.dispatch('viewMe').then(response => {
            console.log("Got some data, now lets show something in this component",obj.users.iduser,response)
                //return this.$store.dispatch('getNotes');
        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })

  },
  created: function() {
      console.log('Bin in Stocks created')
    this.$store.dispatch('getStocks').then(response => {
            console.log("Got some data, now lets show something in this component",response)
                //return this.$store.dispatch('getNotes');
        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })

  },
  computed: {
    ...mapGetters({ stocks: 'stateStocks',users: 'stateUser',}),
  },
  watch: {

    },
  methods: {
    ...mapActions(['createStock','deleteStock','updateStock','createUrladungMitfiles']),
    async submit() {
      console.log('DashboardScreen: submit createNote')
      await this.createNote(this.form);
    },
      getCardcolor(Menge) {
        //var id = parseInt(MengeAsString)
        //console.log('Menge:',Menge)
        if (Menge.localeCompare("0") === 0) return 'grey'
        return 'blue lighten-1'
      },
    async createUrladungMitFiles(item) {
      console.log('createUrladungMitFiles',item.idstocks)

      var obj = this


        const formData = new FormData();
        formData.append("file",  obj.uploadedfile)
        //formData.append('assembly_idassembly_id', obj.assemblies[0].idassembly);
        formData.append('idstocks', item.idstocks);


        //this.$store.dispatch('getStockhistories',ausgewaehlteStockIDs)

        obj.$store.dispatch('createUrladungMitfiles',formData).then(response => {
            console.log("Got some data, now lets show something in this component",response)

        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })


    },
    async createStockOnDB(item) {
      console.log('Async submit createStockOnDB', item.idstocks,item.stocks_name)

      var obj = this


            var today = new Date();


              var neuerStock= {
              "stocks_name": item.stocks_name,
              "stocks_Handelsplatz": item.stocks_Handelsplatz,
              "stocks_GoogleId": item.stocks_GoogleId,
              "stocks_Kaufdatum": today,
              "stocks_ISIN": item.stocks_ISIN,
              "stocks_Kaufkurs": item.stocks_Kaufkurs,
              "stocks_Menge": item.stocks_Menge.toString(),
              "stocks_Status": 1,
              "stock_Umrechnungskurs": 1,
              "stock_Realsiert": 0,
              "stock_Aktuellerkurs": 0,
              "stocks_Land": item.stocks_Land,
              "stocks_stoploss": 0,
              "stock_25ProzentVerkauflimit": 0,
              "stock_delta1_prozent": 0,
              "stock_delta5_prozent": 0,
              "stock_delta20_prozent": 0,
              "stocks_symbol": "string",
              "stocks_symbol_typ": "string",
              "user_iduser_id": 1
            }


            obj.createStock(neuerStock).then((res) => {
                  console.log('stock created:',res)
                  //console.log('neuesAssemblyObject',neuesAssemblyObject, neuesAssemblyObject.idassembly)

                })
                .catch((error) => {
                  console.error(error);
                });



    },
    onFileChange(item,e) {
      console.log('Bin in onFileChange',item)
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.uploadedfile = files[0]
      this.createUrladungMitFiles(item)
      //this.createImage(files[0]);
    },

    async createTransaction(anderungskennzeichen,item) {
        console.log('Async submit createTransactio', anderungskennzeichen, item)

        var obj = this

        const TracForm = new FormData();
        TracForm.append('idstocks', this.editedItem.stocks_idstocks);
        TracForm.append('aenderungskennzeichen', anderungskennzeichen);
        TracForm.append('menge', this.editedItem.menge);
        TracForm.append('preis', this.editedItem.preis);


        this.$store.dispatch('createTransaction',TracForm).then(()=>{
        console.log("Got some data, now lets show something in this component")

            //item.stocks_Menge=obj.editedItem.menge

            obj.close()

        }, error => {
        console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })
    },


    async updateStockOnDB(item) {
      console.log('Async submit updateStockOnDB', item.idstocks,item.stocks_name, item.user_iduser.full_name)

      var obj = this

      var updatedstock = item
      //updatedstock['user_iduser'] = item.user_iduser.iduser
      //delete updatedstock.user_iduser
      //updatedstock['user_iduser_id'] = 1

      console.log('--updatedstock:', updatedstock.idstocks,item.stocks_name, updatedstock.user_iduser)



           updatedstock =      {
          "idstocks": item.idstocks,
          "stocks_name": item.stocks_name,
          "stocks_Handelsplatz":item.stocks_Handelsplatz,
          "stocks_GoogleId": item.stocks_GoogleId,
          "stocks_Kaufdatum": item.stocks_Kaufdatum,
          "stocks_ISIN": item.stocks_ISIN,
          "stocks_Kaufkurs": item.stocks_Kaufkurs,
          "stocks_Menge": item.stocks_Menge.toString(),
          "stocks_Status": item.stocks_Status,
          "stock_Umrechnungskurs": item.stock_Umrechnungskurs,
          "stock_Realsiert": item.stock_Realsiert,
          "stock_Aktuellerkurs": parseFloat(item.stock_Aktuellerkurs),
          "stocks_Land": item.stocks_Land,
          "stocks_stoploss": item.stocks_stoploss,
          "stock_25ProzentVerkauflimit": item.stock_25ProzentVerkauflimit,
          "stock_delta1_prozent": item.stock_delta1_prozent,
          "stock_delta5_prozent": item.stock_delta5_prozent,
          "stock_delta20_prozent": item.stock_delta20_prozent,
          "user_iduser_id": 1
        }



            obj.updateStock(updatedstock).then((res) => {
                  console.log('stock saved:',res)

                })
                .catch((error) => {
                  console.error(error);
                });

    },
    async deleteStockOnDB(item) {
      console.log('Async submit deleteStockOnDB', item.idstocks,item.stocks_name)

      var obj = this


            obj.deleteStock(item.idstocks).then((res) => {
                  console.log('stock deleted:',res)
                  //console.log('neuesAssemblyObject',neuesAssemblyObject, neuesAssemblyObject.idassembly)

                })
                .catch((error) => {
                  console.error(error);
                });



    },
    async urladungStockOnDB(item) {
      console.log('Async submit urladungStockOnDB', item.idstocks,item.stocks_name)

      var obj = this

        const formData = new FormData();
        formData.append('idstocks', item.idstocks);

        obj.dialogUrladungRunning = true

        obj.$store.dispatch('createUrladung',formData).then(response => {
            console.log("Got some data, now lets show something in this component",response.download_stockhistory )
            obj.dialogUrladungRunning = false
            if (response.download_stockhistory.slice(0, 2) == '-E'){
                obj.dialogUrladungAlert = true
            }

        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
            obj.dialogUrladungRunning = false
        })





    },
      createStockGUI: function (schalter) {
        console.log('Bin createStock',schalter)
        var neuerStock = { "idstocks": 'x',
        "stocks_name": "???",
        "stocks_Handelsplatz": "???",
        "stocks_GoogleId": "???", "stocks_Kaufdatum": "2020-02-08T16:15:13Z",
        "stocks_ISIN": "???", "stocks_Kaufkurs": 0,
        "stocks_Menge": "0", "stocks_Status": 1,
        "stock_Umrechnungskurs": 1, "stock_Realsiert": "x",
        "stock_Aktuellerkurs": 0, "stocks_Land": "???",
        "stocks_stoploss": "???", "stock_25ProzentVerkauflimit": "x",
        "stock_delta1_prozent": 0.1, "stock_delta5_prozent": 11.55,
        "stock_delta20_prozent": 11.55,
        //"user_iduser": { "iduser": 1, "user_username": "joe", "full_name": "joe_fullname", "user_UUID": "2435243_AEFD_tfgrt_joe", "user_Seriennummer_Android": "735c779137568412" },
        "user_iduser": { "iduser": 0, "user_username": "x", "full_name": "x", "user_UUID": "x", "user_Seriennummer_Android": "x" },
        "stock_Aktuellerkurs_gesamt": 0, "stock_StopLoss_Euro": 0 }


        console.log('Bin createStock',neuerStock)
        this.CreateModus = true
        this.stocks.unshift(neuerStock)
        //this.createStockV2()
        //this.array_alleStocks.unshift(neuerStock)
        this.zeigeStocksAllerUser = true
      },
      addStocks: function (idstock,name,Kaufkurs) {
        console.log('Bin addStocks',idstock,name,Kaufkurs)
        this.editedItem.stocks_idstocks = idstock
        this.editedItem.aenderungskennzeichen = 1 //Zukauf
        //this.editedItem.menge = menge
        //this.editedItem.preis = Kaufkurs
        //var neuerStock = [idstock,name,'x','x','x','x','x',Kaufkurs]
        //this.array_alleStocks.unshift(neuerStock)
      },
      subtractStocks: function (item) {

        console.log('Bin subtractStocks',item.idstocks,item.stocks_name)
        this.editedItem.stocks_idstocks = item.idstocks
        this.editedItem.aenderungskennzeichen = 2 //Verkauf
      },
      showall: function (schalter) {
        console.log('showall',schalter)

      },
      close () {
        this.dialog = false
        this.dialogSubtract = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
  },
};
</script>