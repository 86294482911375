<template>

      <v-card
        v-if="stammdaten"
        class="mx-auto"
      >
         <v-toolbar
            color="rgba(0, 0, 0, 0)"
            theme="dark"
          >
            <template v-slot:prepend>
                <v-avatar>
                    <v-img
                    alt="Avatar"
                    :src='"/userdir/stocksymbols/symbol_"+ this.idstock +"." + "png"'
                    ></v-img>
                </v-avatar>
            </template>

            <v-toolbar-title class="text-h6">
              {{this.stocks_name + ' - ' + this.nextuser.user_username}}
            </v-toolbar-title>

            <template v-slot:append>
              <v-checkbox v-model="prozent" label="%" ></v-checkbox>
              <v-btn density="default" @click="nextstock" icon="mdi-skip-next"></v-btn>

            </template>
          </v-toolbar>
          <v-card-text>
              <div>realisiertGesamt (inkl. Dividende):</div>
              <p class="text-h6 text--primary">
                {{realisiertGesamt}} €
              </p>

            </v-card-text>

      </v-card>
         <v-combobox
              v-model="selectedStocks"
              @update:modelValue="onChangeCombo3"
              :items="stocks"
              item-title="stocks_name"
              item-value="idstocks"
              :item-props="itemProps"
              label="Select a favorite activity or create a new one"
              return-object
              multiple
            >

        </v-combobox>

        <v-btn
        @click="DrawOneYear"
        >Analyze all</v-btn>

        <v-btn
        @click="filterDates(12)"
        >1 year</v-btn>

        <v-btn
        @click="filterDates(36)"
        >3 years</v-btn>

        <v-btn
        @click="DividendeSteuer"
        >Div./Steuer €</v-btn>

        <v-checkbox
            v-if="!stammdaten"
            v-model="prozent" label="%" ></v-checkbox>
            <v-card>
              <div style="position: relative;  max-width:800px">
                <canvas id="line-chart"></canvas>
               </div>
            </v-card>

    <h3>Strategie Vergleich - Basierend auf kompletter Historie</h3>

        <v-table theme="dark">

        <tbody>
          <tr>
            <td>ersterKurs</td>
            <td>{{ preisdeltas[0].ersterKurs}} €</td>
          </tr>
          <tr>
            <td>letzterKurs</td>
            <td>{{ preisdeltas[0].letzterKurs}} €</td>
          </tr>
          <tr>
            <td>letzterKaufkurs</td>
            <td>{{ preisdeltas[0].letzterKaufkurs}} €</td>
          </tr>
          <tr>
            <td>letzterVerkaufkurs</td>
            <td>{{ preisdeltas[0].letzterVerkaufkurs}} €</td>
          </tr>
          <tr>
            <td>PreisdeltaSignalstrategie</td>
            <td>{{ preisdeltas[0].PreisdeltaSignalstrategie}} €</td>
          </tr>
          <tr>
            <td>PreisdeltaHoldstrategie</td>
            <td>{{ preisdeltas[0].PreisdeltaHoldstrategie}} €</td>
          </tr>
        </tbody>
      </v-table>

        <div v-if="stammdaten">
            <v-list v-for="item in articles" :key="item">
              <v-card
                class="mx-auto"
                outlined
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4" v-text="new Date(item.articles_datum).toLocaleString()">
                    </div>
                    <v-list-item-title class="headline mb-1" v-text="item.articles_stockname">
                    </v-list-item-title>
                    <v-list-item-subtitle>delta %</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    tile
                    size="80"
                    color="grey"
                  ></v-list-item-avatar>
                </v-list-item>

                <v-card-text v-text="item.articles_headline"></v-card-text>
                <v-card-text v-text="item.articles_langtext"></v-card-text>
                <v-card-actions>
                    <v-btn       outlined

                        @click="openFile(item.articles_URL)"
                      >
                        zum kompletten Artikel
                    </v-btn>
                </v-card-actions>

              </v-card>
            </v-list>

                <v-dialog v-model="dialog" max-width="500px">
                <v-card
                  class="mx-auto"

                >
                   <span class="headline">Dividende oder Steuer (gewinn immer Plus)</span>
                   <span >Brauch ich aendeungskennzeichen 4 gibt es Minus bei Steuer ??</span>
                    <v-select
                      label="Typ"
                      v-model="editedItem.typ"
                      :items="typen"
                        item-title="typ"
                        item-value="wert"
                    ></v-select>
                    <v-text-field v-model="editedItem.menge" label="Menge" hint="1.2" ></v-text-field>


                   <v-text-field v-model="editedItem.preis" label="Ver/Kauf-Preis PRO Stock oder Dividende" hint="(immer positiv) in Euro pro Stück" ></v-text-field>
                   <v-text-field v-model="editedItem.beschreibung" label="beschreibung"></v-text-field>


                   <v-btn color="blue darken-1" @click="close">Cancel</v-btn>
                   <v-btn color="blue darken-1" @click="createTransaction(item)">Save</v-btn>

                 </v-card>

                <v-card>
                    <v-data-table
                    :headers="headers"
                    :items="transactions"
                    :search="search"
                    :items-per-page=40
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50, 100]
                    }"
                    density="compact"
                    @click:row="handleClickActivty"
                    class="elevation-1 text-caption"
                    >

                        <template v-slot:item.stocks_transactions_datum="{ item }">
                          <div v-text="new Date(item.stocks_transactions_datum).toLocaleDateString()"></div>
                        </template>

                    </v-data-table>
                </v-card>


                </v-dialog>



        </div>  <!-- if stammdaten -->

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Chart, registerables  } from "chart.js";
Chart.register(...registerables);
import _ from 'lodash'

export default {
  name: 'AnalyzerScreen',
   data () {
    return {
        selectedStocks:[],
        prozent: false,
        stammdaten: false,
        idstock: 0,
        stocks_name: '',
        nextuser: '',
        dividendeGesamt:0,
        realisiertGesamt:0,
        dialog: false,
              headers: [
        { title: 'Typ',
            fixed: true,
            key: 'stocks_transactions_category',align: 'end'},
        {
          title: 'Menge',
          align: 'start',
          key: 'stocks_menge',
        },
        {
          title: 'Preis',
          align: 'start',
          key: 'stocks_transactions_preis',
        },
        {
          title: 'Datum',
          align: 'start',
          key: 'stocks_transactions_datum',
        },
        {
          title: 'Beschreibung',
          align: 'start',
          key: 'stocks_transactions_beschreibung',
        },
      ],
        editedItem: {
          typ: 4,
          key: -1,
          preis: 0,
          datum: 0,
          menge: 0,
          beschreibung: '',

        },
        typen: [
          { typ: 'Zukauf', wert: 1 },
          { typ: 'Verkauf', wert: 2 },
          { typ: 'neues StopLoss Limit', wert: 3 },
          { typ: 'Dividende', wert: 4 },

        ],
        lineChartData : {
          type: 'line',
          data: {
            labels: ["January", "February", "March", "April", "May", "June", "July"],
            datasets: [
              { // one line graph
                label: "Kurs 1(€)",
                data: [0, 0, 1, 2, 67, 62, 27, 14],
                fill:false,
                pointRadius: 0,
                borderColor: 'Orange',
                borderWidth: 2,
                yAxisID: "y-axis-0",
              },
              { // one line graph
                label: "Kurs 2(€)",
                data: [0, 30, 41, 22, 67, 162, 27, 14],
                fill:false,
                pointRadius: 0,
                borderColor: 'Green',
                borderWidth: 2,
                yAxisID: "y-axis-0",
              },
              { // another line graph
                label: "Trend (%)",
                data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
                fill:true,
                borderColor: 'Blue',
                pointRadius: 0,
                borderWidth: 1,
                yAxisID: "y-axis-1",
              }
            ]
          },
          options: {
            responsive: true,
            lineTension: 0.5,
            scales: {
                yAxes: [{
                          stacked: false, //damit werden die data lines adiiert !!! das will ich nicht
                          position: "left",
                          id: "y-axis-0",
                        }, {
                          stacked: false,
                          position: "right",
                          id: "y-axis-1",
                        }],
                  }//scales
          }//options
        },
    }
  },
      watch: {
      model (val, prev) {
        if (val.length === prev.length) return

        this.model = val.map(v => {
          if (typeof v === 'string') {
            v = {
              text: v,
              color: this.colors[this.nonce - 1],
            }

            this.items.push(v)

            this.nonce++
          }

          return v
        })
      },
    },
  created: function() {

    this.articles = []
    console.log('-->>Bin in analyzer created',this.articles)
    this.$store.dispatch('getSettings')
    return this.$store.dispatch('getStocks');
  },
  computed: {
    ...mapGetters({articles: 'stateArticles', stocks: 'stateStocks', stockhistories: 'stateStockhistories',
    getSettingBySettingkey: 'getSettingBySettingkey',preisdeltas: 'statePreisdeltas',transactions: 'stateTransactions'}),
  },
  mounted: function () {
        console.log('Bin analyzer mounted',this.$route.params)
        this.idstock = this.$route.params.idstocks
        this.stocks_name = this.$route.params.stocks_name
        var ausgewaehlteStockIDs = [] //payload
        var obj  = this
        if(obj.idstock)
        {
            console.log('Bin analyzer mounted...with Activityid:', obj.idstock )
            this.stammdaten = true

            var treffer = false

            for (var i = 0; i < this.stocks.length; i++) {
              for (var key in obj.stocks[i]) {
                //console.log(key + ' == ' + obj.stocks[i][key],obj.idstock);
                if (key == 'idstocks' &&  treffer == true){
                    console.log('----###Dahin blaettern',obj.stocks[i][key])


                    treffer = false
                    break
                }
                if (key == 'idstocks' &&  obj.stocks[i][key] == obj.idstock){
                    console.log('Treffer, jetzt den naechsten holen und damit blaettern')
                    obj.nextuser = obj.stocks[i]['user_iduser']
                    treffer = true
                    break
                }
              }
            }


            this.stammdatenAktualsieren()



        }
        else{
            this.stammdaten = false
            ausgewaehlteStockIDs = [111,112,114] //payload
            console.log('>>ausgewaehltestocks',ausgewaehlteStockIDs)
            this.$store.dispatch('getStockhistories',ausgewaehlteStockIDs).then(()=>{
                console.log('trends',this.stockhistories)
                //var liste = JSON.parse(this.stockhistories)
                this.listGlobal = this.stockhistories
                this.makeList(this.listGlobal)
                this.drawChart()


                }, error => {
                console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
                })  //hier ist der AUfruf wie mit Parameter aus settings db?



        }

      //settings einlesen: this.$store.getters.getSettingsByKey('Stockid1')

      var set1 = this.getSettingBySettingkey('Stockid1') // -> { id: 2, text: '...', done: false }
      console.log('Settingbykey',set1,set1.settings_value)

      //this.drawChart()


      },
  methods: {
    ...mapActions(['createStock']),
    onChangeCombo3(entry){
        console.log('So this is your favorite number: ',entry);
    },
    DividendeSteuer(){
        console.log('DividendeSteuer ');
        this.dialog = true;
    },
    nextstock(){
        var obj = this
        console.log('nextstock ',this.idstock,this.stocks);

        var treffer = false

        for (var i = 0; i < this.stocks.length; i++) {
          for (var key in obj.stocks[i]) {
            //console.log(key + ' == ' + obj.stocks[i][key],obj.idstock);
            if (key == 'idstocks' &&  treffer == true){
                console.log('----###Dahin blaettern',obj.stocks[i][key])
                //obj.$router.push({name: 'Analyzer', params: {idstocks: 24,stocks_name: 'Telekom'}})
                //nicht blaettern sondern alles tauschen!
                obj.stocks_name = obj.stocks[i]['stocks_name']
                obj.idstock = obj.stocks[i]['idstocks']
                obj.nextuser = obj.stocks[i]['user_iduser']
                obj.stammdatenAktualsieren()

                treffer = false
                break
            }
            if (key == 'idstocks' &&  obj.stocks[i][key] == obj.idstock){
                console.log('Treffer, jetzt den naechsten holen und damit blaettern')
                treffer = true
            }
          }
        }




    },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
    async stammdatenAktualsieren () {
            var obj  = this
            var ausgewaehlteStockIDs = [] //payload
            ausgewaehlteStockIDs.push(obj.idstock);
            console.log('>>ausgewaehltestocks',ausgewaehlteStockIDs)
            this.$store.dispatch('getStockhistories',ausgewaehlteStockIDs).then(()=>{
                console.log('trends',this.stockhistories)
                //var liste = JSON.parse(this.stockhistories)
                this.listGlobal = this.stockhistories
                this.makeList(this.listGlobal)
                this.drawChart()



                }, error => {
                console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
                })  //hier ist der AUfruf wie mit Parameter aus settings db?


            this.$store.dispatch('getPreisdeltas',ausgewaehlteStockIDs).then(response => {
                console.log("Got some Preisdeltas, now lets show something in this component",response)

            }, error => {
                console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
            })

            const TracForm = new FormData();
            TracForm.append('idstock', obj.idstock);
            this.$store.dispatch('getArticles',TracForm).then(()=>{
                //console.log("Got some data, now lets show something in this component",this.articles)
                //refresh map


                }, error => {
                    console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
                })



            this.$store.dispatch('getTransactions',obj.idstock).then(()=>{
                console.log("Got some data, now lets show something in this component",this.transactions)
                //dividende summieren
                obj.realisiertGesamt = _.sumBy(this.transactions, 'stocks_transactions_preis');
                obj.realisiertGesamt = Math.round(obj.realisiertGesamt * 100) /100
                console.log('##realisiertGesamt:',obj.realisiertGesamt)


                }, error => {
                    console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
                })
      },
    async createTransaction() {
        console.log('Async submit createTransaction', this.editedItem)

        var obj = this

        const TracForm = new FormData();
        TracForm.append('idstocks', this.idstock);
        TracForm.append('aenderungskennzeichen', 3);
        TracForm.append('preis', this.editedItem.preis);


        this.$store.dispatch('createTransaction',TracForm).then(()=>{
        console.log("Got some data, now lets show something in this component")
                obj.dialog = false
                setTimeout(() => {
                  this.editedItem = Object.assign({}, this.defaultItem)
                  this.editedIndex = -1
                }, 300)


        }, error => {
        console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })
    },

      itemProps (item) {
        return {
          title: item.stocks_name,
          subtitle: item.user_iduser.user_username,
        }
      },

    filterDates (Tage) {

        console.log('filterDates', Tage)
        //console.log('globale Liste:',this.listGlobal)
        console.log('Satz1:',this.listGlobal[0]['datum'], this.listGlobal.length)
        var filteredlist
        if(Tage!=0){
                filteredlist = this.listGlobal.slice(this.listGlobal.length - Tage, this.listGlobal.length)

            }
        else{
                filteredlist = this.listGlobal
            }
        console.log('--#len list:',filteredlist.length)
        console.log('filterDates letzte Tage:',filteredlist,Tage)
        this.makeList(filteredlist)
        this.drawChart()


      },
    DrawOneYear(){
        console.log('Buttonon 1 year pressed ');
        console.log('selectedStocks.',this.selectedStocks)

        //warum muss ich 2 mal druecken damit es aktualsiert wird? vielleicht erst auf den return wert warten

        var ausgewaehlteStockIDs = [] //payload

        this.selectedStocks.forEach((x, i)=>{
            console.log('-+++',i,x.idstocks)
            ausgewaehlteStockIDs.push(x.idstocks);
        })


        //this.$store.dispatch('getStockhistories',ausgewaehlteStockIDs)

		this.$store.dispatch('getStockhistories',ausgewaehlteStockIDs).then(response => {
            console.log("Got some data, now lets show something in this component",response)
            this.listGlobal = this.stockhistories
            this.makeList(this.listGlobal)
            this.drawChart()
        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })



        //}  //hier ist der AUfruf wie mit Parameter aus settings db?



        //this.makeList(this.listGlobal)
    },
    drawChart() {

        // JS - Destroy exiting Chart Instance to reuse <canvas> element
        let chartStatus = Chart.getChart("line-chart"); // <canvas> id
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }
        //-- End of chart destroy

      var obj = this

        //check if we are on a mobile browser

        //alert(answer ? 'It is a mobile device' : 'It is not a mobile device');
        if (Math.min(window.screen.width, window.screen.height) < 768) {

        //y-Achse rechts setzen und x-Achsen labels ausblenden auf mobile
          obj.lineChartData.options.scales = {
                yAxes: [{
                          stacked: false, //damit werden die data lines adiiert !!! das will ich nicht
                          position: "left",
                          id: "y-axis-0",
                        }, {
                          stacked: false,
                          position: "right",
                          id: "y-axis-1",
                        }],
            x: {
                  ticks: {
                    display: false,
                  },

                  // to remove the x-axis grid
                  grid: {
                    drawBorder: false,
                    display: false,
                  },
             },
          }

        }


      var neueLineChartDaten = obj.lineChartData

          //draw chart
          const ctx = document.getElementById('line-chart');
            //  console.log('chartdata:',chartData.data)

            var myChart = new Chart(ctx, { // eslint-disable-line
              type: neueLineChartDaten.type,
              data: neueLineChartDaten.data,
              options: neueLineChartDaten.options,
          });
          console.log('aha',myChart)
    },
    makeList(filteredListGlobal){

        var obj = this

        obj.lineChartData.data.labels = []

        var counter = 0
        //var linecolors = ['Orange','Green','Blue','White','Purple','Grey','Red','Yellow']
        var linecolors = ['#e4f2f2','#a0b58b','#4366d1','#d1437c','Purple','Grey','Red','Yellow']

        obj.lineChartData.data.datasets =[]

                // Looping through arrays created from Object.keys
                const keys = Object.keys(filteredListGlobal[0])
                for (const key of keys) {
                  //console.log('LOAD>>>>>>>',key)
                  if(key === 'datum')
                    console.log('---make List-----nada')
                  else if (key === 'trend'){
                    console.log('-make List--TREND')
                     var datasetDynRow = {}
                        datasetDynRow['label'] = key
                        datasetDynRow['data'] = [1,2,4,7,8]
                        datasetDynRow['fill'] = false
                        datasetDynRow['pointRadius'] = 0
                        datasetDynRow['borderDash'] = [10, 10]
                        datasetDynRow['borderColor'] = linecolors[3]
                        datasetDynRow['borderWidth'] = 2
                        datasetDynRow['yAxisID'] = 'y-axis-1'
                        //console.log('ee>>>>>',datasetDynRow)

                         //nachdem die Column bekannt ist das entsprechende dataset einlesen
                         datasetDynRow['data'] = []
                               for(var x in filteredListGlobal){
                                  //arr.push(parsed[x]);
                                  //
                                  //console.log("########Wert:",liste[x][key])
                                  datasetDynRow['data'].push(filteredListGlobal[x][key])
                                  if(counter === 0){//nur einmal fuer labels
                                    obj.lineChartData.data.labels.push(filteredListGlobal[x]['datum'])
                                    }

                              }
                         obj.lineChartData.data.datasets.push(datasetDynRow)

                 }
                 else{ //Kurswert und kein Trend
                       datasetDynRow = {}
                        datasetDynRow['label'] = key
                        datasetDynRow['data'] = [1,2,4,7,8]
                        datasetDynRow['fill'] = true
                        datasetDynRow['pointRadius'] = 0
                        datasetDynRow['borderColor'] = linecolors[counter]
                        datasetDynRow['borderWidth'] = 1
                        datasetDynRow['yAxisID'] = 'y-axis-0'
                        //console.log('ee>>>>>',datasetDynRow)

                         //nachdem die Column bekannt ist das entsprechende dataset einlesen
                         datasetDynRow['data'] = []
                               for(x in filteredListGlobal){
                               //for(var x in [0,1,2,3]){
                                  //arr.push(parsed[x]);
                                  //
                                  //console.log("########Wert:",liste[x][key])
                                  if(obj.prozent === true){ //Prozentuale Werte

                                  var prozentualerWert = (filteredListGlobal[x][key]/filteredListGlobal[0][key])*100
                                  //console.log("#####PROZENT###Wert:",key, prozentualerWert)

                                  datasetDynRow['data'].push(prozentualerWert)
                                  //datasetDynRow['data'].push(200)

                                  }
                                  else{ //absolute Werte
                                  datasetDynRow['data'].push(filteredListGlobal[x][key])
                                  //console.log("#####ABSOLUT###Wert:",liste[x][key])

                                  }


                                  if(counter === 0){//nur einmal fuer labels
                                    obj.lineChartData.data.labels.push(filteredListGlobal[x]['datum'])
                                    //lineChartDatadyn.data.labels.push(liste[x]['datum'])
                                    }

                              }

                         //console.log("#####PROZENT###WertList:",key, datasetDynRow)
                         obj.lineChartData.data.datasets.push(datasetDynRow)


                         counter +=1

                  }
              }
      },
  },
};
</script>