// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
//import { VuetifyDateAdapter } from 'vuetify/date/adapters/vuetify'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'

export default createVuetify({
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    theme: {
        defaultTheme: 'dark'
    },
//    date: {
//        adapter: VuetifyDateAdapter,
//    },
    components: {
    ...components,
    ...labsComponents,
  },
})
