import createPersistedState from "vuex-persistedstate";
//import Vue from 'vue';
import Vuex from 'vuex';

import notes from './modules/notes';
import users from './modules/users';
import settings from './modules/settings';
import stocks from './modules/stocks';
import stockhistories from './modules/stockhistories';
import bilder from './modules/bilder';
import activities from './modules/activities';
import assemblies from './modules/assemblies';
import unknowns from './modules/unknowns';
import tracks from './modules/tracks';
import geoheatmaps from './modules/geoheatmaps';
import rankings from './modules/rankings';
import articles from './modules/articles';
import transactions from './modules/transactions';
import resources from './modules/resources';
//Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notes,
    users,
    settings,
    stocks,
    stockhistories,
    bilder,
    activities,
    assemblies,
    unknowns,
    tracks,
    geoheatmaps,
    rankings,
    articles,
    transactions,
    resources,
  },
  plugins: [createPersistedState()]
});