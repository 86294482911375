<template>
    <!-- App.vue -->

    <v-app id="Rateit4#P">
        <div v-if="navbar">
                <v-navigation-drawer
                    permanent
                >
                    <MenueBar></MenueBar>
                  <v-list>
                    <v-list-item @click="right = !right">
                      <v-list-item-action>
                        <v-icon>compare_arrows</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>Switch drawer (click me)</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-navigation-drawer>
        </div>

      <v-app-bar app>
        <!-- -->
              <v-app-bar-nav-icon @click="navbar = !navbar"></v-app-bar-nav-icon>

              <v-toolbar-title>Rateit4</v-toolbar-title>
              <div v-if="TargetEnv=='NC160V4_INT'">
                  <v-chip
                  class="ma-2"
                  color="red"
                  text-color="white"
                    >
                      **INT**
                    </v-chip>
              </div>

              <div v-if="TargetEnv=='NC160V3_PROD'">
                  <v-chip
                  class="ma-2"
                  color="orange"
                  text-color="white"
                    >
                      **Prod**
                    </v-chip>
              </div>

              <v-chip
                size="x-small"
                >
                {{SVNVersionSumme}}
                </v-chip>

              <div v-if="TargetEnv=='Windows'">
                  <v-chip
                  class="ma-2"
                  color="green"
                  text-color="white"
                    >
                      **DEV**
                    </v-chip>
              </div>


               <v-chip
                  color="orange"
                  text-color="white"
                >
                  -{{aktuellerUser}}-
                </v-chip>

                <v-btn variant="text" @click.stop="drawer = !drawer" icon="mdi-dots-vertical"></v-btn>



      </v-app-bar>


      <v-navigation-drawer
        v-model="drawer"
        location="right"
        temporary
      >
      <div class="container">
        <a class="navbar-brand" href="/">FastAPI + Vue</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul v-if="isLoggedIn" class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/dashboard">Dashboard</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/profile">My 7 Profile</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="logout">Log Out</a>
            </li>
            <li class="nav-item">
              <v-chip
                size="x-small"
                >
                Version: {{SVNVersion}} (Backend_Frontend)
              </v-chip>
            </li>
            <li class="nav-item">
              <v-chip
                size="x-small"
                >
                CalVersion: {{CalVersion}} (Deploy Date)
              </v-chip>
            </li>
          </ul>
          <ul v-else class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/register">Register</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/login">Log In</router-link>
            </li>
          </ul>
        </div>
      </div>
      </v-navigation-drawer>


      <!-- Sizes your content based upon application components -->
      <v-main>

        <!-- Provides the application the proper gutter -->
        <v-container fluid>

          <!-- If using vue-router -->
          <router-view></router-view>
  <div id="nav">
      <router-link to="/home">Home</router-link> |
      <router-link to="/splash">Splash</router-link> |
      <router-link to="/fastapi">FasApi</router-link> |
      <router-link to="/static/index.html">vue3</router-link> |
      <router-link to="/about">About</router-link>
  </div>

  <div id="app">
    <NavBar />

  </div>
        </v-container>
      </v-main>

      <v-footer app>
        <!-- -->
      </v-footer>
    </v-app>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import MenueBar from './components/MenueBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    MenueBar
  },
    data () {
    return {
        message:'',
        //neue Rateit4 Stelle fuer globale App variablen
        global_message: 'inital',
        global_kategorieFilter: [],
        global_TagsFilter: [],
        global_textFilter: [],
        global_page_numberofitems: 20,
        global_page: 1,
        global_Assembly_Ansicht: 0, //0 - Kacheln, 1 -Liste
        neu_4_root_data_User: 'Hugo_root_data_User',
        //--------------------------


      TargetEnv: process.env.VUE_APP_SERVER,
      CalVersion: process.env.VUE_APP_CALVERSION,
      SVNVersion: process.env.VUE_APP_SVNVERSION,
      SVNVersionSumme: 0,
      clipped: false,
      drawer: false,
      fixed: false,
      navbar:false,
      items: [
        {
          title: 'Foo',
          value: 'foo',
        },
        {
          title: 'Bar',
          value: 'bar',
        },
        {
          title: 'Fizz',
          value: 'fizz',
        },
        {
          title: 'Buzz',
          value: 'buzz',
        },
      ],
        categories: [],
        tags: [],
      miniVariant: false,
      right: false,
      rightDrawer: false,
        title: 'NEWRateIt 2',
        TextFilterAssemblies: '',
        items_pagenumber: ['10', '20', '50', '100'],
        value_pagenumber: [10, 20, 50, 100],
        page_numberofitems: 20,
        settings_page_numberofitems : 50,
        ansicht : 'Kacheln',
        isActive: false,
        selektierteTags:[],
        dialog:false,
        aktuellerUser: '###',
    }
  },
      computed : {
        isLoggedIn: function() {
          console.log('App: Check if User is logged in')
          return this.$store.getters.isAuthenticated;
        }
      },
    watch: {
    $route(to, from) {
      console.log('wtaching..',to,from)
      this.hideNavbar()
    },
    },
     mounted() {
        this.emitter.on("hideNavBar", (param) => {
              this.message = param;
              console.log('received event hideNavBar message:',this.message.msg)
              this.aktuellerUser = this.message.msg
            });
        this.emitter.on("userLoggedin", (param) => {
              this.message = param;
              console.log('received event userLoggedin:',this.message.msg)
              this.aktuellerUser = this.message.msg
              this.showNavbar()
            });
        this.emitter.on("userLoggedout", (param) => {
              this.message = param;
              console.log('received event userLoggedout:',this.message.msg)
              this.aktuellerUser = '...'
              this.hideNavbar()
            });
        //eine gemeinsame Versions ID durch Addition der revision Numbers
        this.SVNVersionSumme = parseInt(this.SVNVersion.split("_")[0]) + parseInt(this.SVNVersion.split("_")[1])
         console.log('>>CalVersion:',this.CalVersion, this.SVNVersion) //damit komme ich an die Daten, wie mache ich die jetzt reactive

         },
     created() {
//      window.Event.$on('applied', () =>alert('received'))
//      window.Event.$on('aktuellerUser', this.updateAktuellerUser)
//      window.Event.$on('userLoggedout', this.hideNavbar)
//      window.Event.$on('userLoggedin', this.showNavbar)
        //Ist user eingeloggt
        //Falls Ja Navbar anzeigen
        //sonst disablen
        if(this.isLoggedIn){
            this.aktuellerUser = this.$store.getters.stateUser.user_username
            console.log('User ist eingeloggt',this.aktuellerUser);
            this.showNavbar()
        }
        else{
            console.log('!!User ist NICHT eingeloggt');
            this.hideNavbar()
        }

      this.$root.global_message = 'kucki aus App'
      console.log('Aus Vue Instanz vm:',this.$root.global_message) //damit komme ich an die Daten, wie mache ich die jetzt reactive

   },

     methods: {

        showNavbar( ){
          console.log('showNavbar:')
          this.navbar = true


        },
        hideNavbar( ){
          console.log('hideNavbar:')
          this.navbar = false
          //var obj = this
          //nur user spezifische Kategorien holen und deren status : load or not load

        },
        async logout () {
          await this.$store.dispatch('logOut');
          this.emitter.emit("userLoggedout", { msg: 'userLoggedout' });
          this.emitter.emit("userLoggedin", { msg: '...' });
          this.$router.push('/login');
        },

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.main {
  padding-top: 5em;
}
</style>