<template>

  <v-row>
       <v-col
          v-for="ranking in rankings" :key="ranking"
          class="d-flex child-flex"
          cols="4"
        >

            <v-img
            :src="ranking['rankingURL']"
            aspect-ratio="1"
            cover
            class="bg-grey-lighten-2"
            @click="openFile(ranking['rankingURL'])"
          >
            <span class="my-span">
              {{'Last 30 days ' + ranking['rankingURL'].substring(28, 35)}}
            </span>
            </v-img>

      </v-col>
  </v-row>

<div v-for="ranking in rankings" :key="ranking" >




      <v-card v-if = "ranking['rankingURL'].includes('compare')"
        class="mx-auto"
        :title="'Last 30 days ' + ranking['rankingURL'].substring(28, 35)"
        color='blue'

      >
      </v-card>
      <v-card v-else
        class="mx-auto"
        title="Last Year"
        color='orange'

      >
      </v-card>


<v-img

  :src="ranking['rankingURL']"
></v-img>

</div>




</template>
<script>
import { mapGetters} from 'vuex';
  export default {
    data: () => ({
      search: '',


    }),
  mounted: function(){

    console.log('Bin in RankingScreen')
    this.$store.dispatch('getRanking').then(()=>{
        console.log("Got some data, now lets show something in this component",this.rankings)
        //refresh map


        }, error => {
        console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })



  },
    methods: {
    openFile(name) {
        console.log('Open:',name)
        window.open(name);
      },

  },
    computed: {
    ...mapGetters({ rankings: 'stateRankings'}),


    },
  }
</script>