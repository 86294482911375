import axios from 'axios';

const state = {
  resources: null,
  resource: null
};

const getters = {
  stateResources: state => state.resources,
  stateResource: state => state.resource,
};

const actions = {

  //liefert nur zu angemeldetem User
  async getResources({commit}) {
    let {data} = await axios.get('resourceusage');
    //console.log('<-<-<-<-<>>>getResources:',data)
    commit('setResources', data);
  },

};

const mutations = {
  setResources(state, resources){
    //console.log('<-<-<-<-<>>>setResources:',state, resources)
    state.resources= resources;
  },
  setResource(state, resource){
    state.resource = resource;
  },

};


//const mutations = {
//  setResources(state, setResources){
//    console.log('<-<-<-<-<>>>setResources:',state, resources)
//    state.resources= resources;
//  },
//  setResource(state, resource){
//    console.log('<-<-<-<-<>>>setResource:',state, resource)
//    state.resource = resource;
//  },
//};

export default {
  state,
  getters,
  actions,
  mutations
};