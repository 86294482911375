<template>

        <v-list v-for="item in articles" :key="item">
          <v-card
            class="mx-auto"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4" v-text="new Date(item.articles_datum).toLocaleString()">
                </div>
                <v-list-item-title class="headline mb-1" v-text="item.articles_stockname">
                </v-list-item-title>
                <v-list-item-subtitle>delta %</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar
                tile
                size="80"
                color="grey"
              ></v-list-item-avatar>
            </v-list-item>

            <v-card-text v-text="item.articles_headline"></v-card-text>
            <v-card-text v-text="item.articles_langtext"></v-card-text>
            <v-card-actions>
                <v-btn       outlined

                    @click="openFile(item.articles_URL)"
                  >
                    zum kompletten Artikel
                </v-btn>
            </v-card-actions>

          </v-card>
        </v-list>
        <v-flex class="text-xs-center">
          <v-card>
            <v-card-text>
              <v-pagination :length="page_numberofpages" v-model="aktuelleSeite" @input="springeZuSeite"></v-pagination>
            </v-card-text>
          </v-card>

        </v-flex>


</template>
<script>
import { mapGetters} from 'vuex';
  export default {
    data: () => ({
      search: '',
      abDatum: new Date('2023-11-16'),
      abDatumString: '',
      bisDatum: new Date(),
      bisDatumString: '',


    }),
  mounted: function(){

    //Abdatum mit Gestern initialsieren
    const heute = new Date()
    const vor12Wochen = new Date(heute)
    vor12Wochen.setDate(vor12Wochen.getDate() - 90)
    console.log('today:',heute )
    console.log('vor12Wochen:',vor12Wochen )

    this.abDatum = vor12Wochen
    this.bisDatum = heute

    var abDatumString = new Date(this.abDatum.getTime() - (this.abDatum.getTimezoneOffset() * 60000 ))
            .toISOString()
            .split("T")[0];
    var bisDatumString = new Date(this.bisDatum.getTime() - (this.bisDatum.getTimezoneOffset() * 60000 ))
            .toISOString()
            .split("T")[0];

    console.log('Bin in ArticlesScreen')
    const TracForm = new FormData();
    TracForm.append('downsamplefaktor', 3);
    TracForm.append('tracks_serial', '0');
    TracForm.append('abDatum', abDatumString);
    TracForm.append('bisDatum', bisDatumString);



    this.$store.dispatch('getArticles',TracForm).then(()=>{
        console.log("Got some data, now lets show something in this component",this.articles)
        //refresh map


        }, error => {
        console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })



  },
  methods: {
    openFile(name) {
        console.log('Open:',name)
        window.open(name);
      },

  },
    computed: {
    ...mapGetters({ articles: 'stateArticles'}),


    },
  }
</script>