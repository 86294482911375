import axios from 'axios';

const state = {
  articles: null,
  article: null
};

const getters = {
  stateArticles: state => state.articles,
  stateArticle: state => state.article,
};

const actions = {


  async getArticles({commit},payload) {

    const asString2 = new URLSearchParams(payload).toString();
    console.log('--Post:',asString2)

    const url = 'articles?'+asString2
    const {data} = await axios.post(url)

    commit('setArticles', data);
  },



};

const mutations = {
  setArticles(state, articles){
    state.articles = articles;
  },
  setArticle(state, article){
    state.article = article;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};