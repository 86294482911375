<template>



  <v-card
    class="mx-auto"
    color="surface-variant"
    image="https://udo.rateit3.de/assets/img/three-slide/thumb-2.jpg"
    max-width="400"
  >

    <v-card-title
        style="color: #F57C00; "
        >
      System resource Usage
    </v-card-title>

    <v-card-subtitle
        style="color: #F57C00; "
        >
      System Info
    </v-card-subtitle>

    <v-progress-circular
      :model-value="value"
      :rotate="360"
      :size="100"
      :width="15"
      color="teal"
    >
      {{ value }}
    </v-progress-circular>

    <v-table>
      <tr>
        <td>MemoryUsage</td>
        <td>{{ resources[0].MemoryUsage }} %</td>

      </tr>
      <tr>
        <td>DiskUsage</td>
        <td>{{ resources[0].DiskUsage }} %</td>

       </tr>
      <tr>
        <td>CPUUsage</td>
        <td>{{ resources[0].CPUUsage }} %</td>

       </tr>

    </v-table>

  </v-card>


      <v-data-table
        :headers="headers"
        :items="resources[1]"
        density="compact"
        :items-per-page=20
        fixed-header=true
        class="elevation-1 text-caption"
      >


        <template v-slot:item.memory.raw="{ item }">
          <div v-text="Math.round(item.memory.raw.split('MiB /')[0]) + ' MiB'"></div>
        </template>
     </v-data-table>

    <div> containers </div>



      <v-table

        fixed-header
      >
        <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              memory.raw
            </th>
            <th class="text-left">
              cpu
            </th>
            <th class="text-left">
              container ID
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in resources[1]"
            :key="item.name"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.memory.raw }}</td>
            <td>{{ item.cpu }}</td>
            <td>{{ item.container }}</td>
          </tr>
        </tbody>
      </v-table>


</template>


<script>
import { mapGetters} from 'vuex';
export default {
  name: 'SettingsScreen',

    data: () => ({
      item: '',
      value: 10,
      interval: {},
            headers: [
        { title: 'container name',
            fixed: true,
            key: 'name',align: 'start'},
        {
          title: 'memory.raw',
          key: 'memory.raw',
          align: 'end',
        },
        { title: 'cpu',key: 'cpu',align: 'end'},
        { title: 'container ID',key: 'container',align: 'end'},

      ],


    }),
    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    computed: {
      ...mapGetters({ resources: 'stateResources'}),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      }
    },
    mounted: function () {
      console.log('Dispatch System Info')





//      for (let i = 0; i < this.settings.length; i++) {
//        console.log('Settings',this.settings[i])
//        this.array_allSettings.push(this.settings[i])
//      }

    },

    created: function () {
      console.log('Dispatch Resources')

      var obj = this
      this.$store.dispatch('getResources').then(response => {
            console.log("Got some data, now lets show something in this component",response,obj.resources)
                  for (let i = 0; i < this.resources.length; i++) {
                    console.log('resources',obj.resources[i])
                    //this.array_allSettings.push(this.settings[i])
                  }


        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })

      this.interval = setInterval(() => {
        if (this.value === 100) {
          return (this.value = 0)
        }
        this.value += 10
        this.updateResources()
      }, 5000)

    },
    unmounted: function () {
      console.log('+++unmounted--- System Info')
      clearInterval(this.interval);

    },

  methods: {
        updateResources () {
          var obj = this
          this.$store.dispatch('getResources').then(response => {
                console.log("Got some data, now lets show something in this component",response,obj.resources)

            }, error => {
                console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
            })
        },
        close () {
          this.dialog = false
//          setTimeout(() => {
//            this.editedItem = Object.assign({}, this.defaultItem)
//            this.editedIndex = -1
//          }, 300)
        },



        clearCache () {
          // make sure new API is supported
          if (!window.caches) {
              console.log('Clear Service Worker NOT SUPPORTED by browser')
              return;
          }

          caches.keys().then(function(names) {
              console.log('Clear Service Worker cache')
              for (var index = 0; index < names.length; index++) {
                  var name = names[index];
                  caches.delete(name);
              }
          });

        },



  }
}
</script>




<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>


