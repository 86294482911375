<template>
  <section>
    <p>This site is built with FastAPI and Vue.</p>
    <p>And should be able to check production</p>

    <div v-if="isLoggedIn" id="logout">
      <p id="logout">Click <a href="/dashboard">here</a> to view all notes.</p>
    </div>
    <p v-else>
      <span><a href="/register">Register</a></span>
      <span> or </span>
      <span><a href="/login">Log In</a></span>
    </p>
  </section>
</template>
<script>

export default {
  name: 'HomeScreen',
      computed : {
        isLoggedIn: function() {
          console.log('HomeScreen: isLoggedIn')
          return this.$store.getters.isAuthenticated;
        },
      },
      mounted: function () {
          console.log('Bin in HomeScreen')
          //var var1 = this.getEnv('VUE_APP_NOT_SECRET_CODE')
          if (process.env.NODE_ENV === 'production') {
              console.log('Bin in HomeScreen im Modus >>>>PROD<<<')
            } else {
              console.log('Bin in HomeScreen im Modus >>>>DEV<<<< ')
            }

       },
}
</script>