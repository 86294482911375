import axios from 'axios';

const state = {
  bilder: null,
  bild: null
};

const getters = {
  stateBilder: state => state.bilder,
  stateBild: state => state.bild,
};

const actions = {
  async createBild({dispatch}, bild) {
    await axios.post('bild', bild);
    await dispatch('getBilder');
  },
  async createBildmitfiles({dispatch}, payload) {
    console.log('## in store >>createBildmitfiles',payload)
    await axios.post('bildermitfiles', payload);
    await dispatch('getBilder');
  },
  //liefert nur zu angemeldetem User
  async getBilder({commit}, payload) {
    console.log('## in store >>getBilder',payload)
    const asString2 = new URLSearchParams(payload).toString();
    const url = 'bilder?'+asString2
    console.log('<-<-<-<-URL:',url)
    let {data} = await axios.post(url);
    //console.log('<-<-<-<-<>>>getBilder:',data)
    commit('setBilder', data);
  },
  //liefert zu allen Usern
  async postBilder({commit}) {
    console.log('<-<-<-<-<>postBild:')
    let {data} = await axios.post('bilder');
    commit('setBilder', data);
    },
  async viewBild({commit}, id) {
    console.log('viewBild:',id)

    let {data} = await axios.get(`bilder/${id}`);
    commit('setBild', data);
  },
  // eslint-disable-next-line no-empty-pattern
  async updateBild({}, payload) {
    //await axios.patch(`bild/${bild.id}`, bild.form);
    const asString2 = new URLSearchParams(payload).toString();
    const url = 'bild?'+asString2
    let {data} = await axios.post(url);

    console.log('<-<-<-<-<>>>bild:',data)
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteBild({}, id) {
    await axios.delete(`bild/${id}`);
  }
};

const mutations = {
  setBilder(state, bilder){
    state.bilder= bilder;
  },
  setBild(state, bild){
    state.bild = bild;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};