import { createApp } from 'vue'
import mitt from 'mitt';
//import { provide } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import store from './store';

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import './registerServiceWorker'

loadFonts()

axios.defaults.withCredentials = true;
console.log('dircte:',process.env.VUE_APP_SERVER)
if (process.env.VUE_APP_SERVER == 'NC160V3_PROD') {
  console.log('--PROD Linux--')
  axios.defaults.baseURL = '/';  // the FastAPI backend
} else if (process.env.VUE_APP_SERVER == 'NC160V4_INT') {
  console.log('--INT Linux--')
  axios.defaults.baseURL = '/';  // the FastAPI backend
}
else{
  console.log('--DEV Windows--')
  axios.defaults.baseURL = 'http://localhost:8011/';  // the FastAPI backend

}
//if (process.env.NODE_ENV !== 'production') {
//  axios.defaults.baseURL = 'http://localhost:8011/';  // the FastAPI backend
//}else{
//  axios.defaults.baseURL = '/';  // the FastAPI backend
//}

//Redirect User to login after ACCESS_TOKEN_EXPIRE_MINUTES = 30 Minuten
axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch('logOut');
      return router.push('/login')
    }
  }
});

window.globaleEinstellungen = new Map() //remember that new should be lowercase.
window.globaleEinstellungen.set('aktueller_User','?3Test')
window.globaleEinstellungen.set('menue_Gruppen',false)
window.globaleEinstellungen.set('global_yPosition',0)
window.globaleEinstellungen.set('baseURL','hase')
window.globaleEinstellungen.set('global_menu', [['art_track','/alben','Alben',true,'test'],['photo','/imageupload','Add a new stuff',true,'test'],
    ['dashboard','/dashboard','dashboard',true,'test'],['euro_symbol','/stocks','stocks',true,'test'],
    ['trending_up',"/charts",'charts',true,'test'],['article',"/articles",'articles',true,'test'],
    ['track_changes',"/analyzer",'analyzer',true,'test'],['bar_chart',"/rankings",'rankings',true,'test'],
    ['remove_shopping_cart','/transactions','historie',true,'test'],
    ['directions_bike',"/activities",'activities',true,'test'],    ['calendar_today',"/calendar",'calendar',true,'test'],
    ['location_on',"/tracks2",'Tracks',true,'test'],['beenhere',"/trackscluster",'Track Cluster',true,'test'],
    ['airport_shuttle',"/transactions",'Livetrack',true,'test'],
    ['account_balance',"/System",'System',true,'test'],['settings',"/Settings",'Settings',true,'test'],
    ['group',"/mygroups",'MyGroups',true],['lightbulb_outline',"/wishlist",'Wishlist',true],
    ['scanner','/scanner','Scanner',true],['description',"/masterdata",'MasterData',true],['face',"/users",'myFriends',true],['loyalty',"/TrainObjects",'Train Image',true,'test'],
    ['notes',"/editor",'editor',true],['info',"/guitest",'guitest',true],])


const emitter = mitt();

const vm = createApp(App)
vm.use(vuetify)
vm.use(router)
vm.use(store)
vm.config.globalProperties.emitter = emitter
vm.mount('#app')



console.log('Started',vm)


// we need to provide it to the app to be used globally
//provide('eventBus', eventBus)
// vm.provide('eventBus',eventBus);


