<template>
  <div>
      <v-list>
        <v-list-item
          value="true"
        >
          <v-list-item-action>
           <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
               <v-list-item-title><a  href="/">Home</a></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list>
        <v-list-item
          value="true"
          v-for="(item, i) in menu"
          :key="i"
          @click="$router.push(item[1])"
        >

             <v-list-item-content>
                <v-list-item-title v-if="item[4]=='finance'" class='text-green-lighten-1' v-text="item[2]"></v-list-item-title>
                <v-list-item-title v-else-if="item[4]=='sport'" class="text-orange-lighten-1" v-text="item[2]"></v-list-item-title>
                <v-list-item-title v-else-if="item[4]=='test'" class="text-yellow" v-text="item[2]"></v-list-item-title>
                <v-list-item-title v-else v-text="item[2]"></v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list>


  </div>
</template>
<script>
  export default {
    name: 'MenueBar',

    data() {
      return {
        menu: [['home','/','Hago',true,'finance'], ['art_track','/vue','Hugo',true,'sport']]
      }
    },

    created() {
      console.log('Update MenueBar')
      //window.Event.$on('menuRefreshNeeded', this.updateMenu)
      //wie kriege ich need for update mit
      this.updateMenu()
    },
    methods: {
      updateMenu(){
      console.log('Aktive id:',sessionStorage.getItem('BilderID'))
      const global_menu = window.globaleEinstellungen.get('global_menu')

      //lokales Menu erzeugen durch umkopieren und pruefen des globalen menues
      this.menu=[]
        for ( var i = 0; i < global_menu.length; i++ ) {

              if (( global_menu[i][2] === 'MasterData' ) && (sessionStorage.getItem('BilderID')===null))
              {console.log('MasterData im Menu deaktivieren')}
              else{this.menu.push(global_menu[i])}
        }

      }
    }
  }
</script>
