<template>
----------------- CLUSTER SCREEN

{{geoheatmaps}}

  <v-form>
    <v-container>
      <v-row>

      <v-menu v-model="isMenuOpen" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-text-field
            label="abDatum"
            :model-value="computedabDatumFormattedDatefns"
            readonly
            v-bind="props"
          ></v-text-field>
        </template>
        <v-date-picker v-model="abDatum"></v-date-picker>
      </v-menu>

      <v-menu v-model="isMenuOpenBisDatum" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-text-field
            label="bisDatum"
            :model-value="computedbisDatumFormattedDatefns"
            readonly
            v-bind="props"
          ></v-text-field>
        </template>
        <v-date-picker v-model="bisDatum"></v-date-picker>
      </v-menu>

      </v-row>
    </v-container>
  </v-form>




<v-btn
  size="small"
  variant="flat"
  color= 'secondary'
  :href='"/"+geoheatmaps[0]["specific_HEATMAP_URL"]' target="_blank"
>
  Show Map
</v-btn>

<v-btn
  size="small"
  variant="flat"
  color= 'secondary'
  @click="updateMap"
>
  Update Map
</v-btn>

<iframe id="theFrame" :src='"/"+geoheatmaps[0]["specific_HEATMAP_URL"]' style="height:1000px;width:100%;" frameborder="0">
</iframe>







</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ref } from 'vue'
import { format } from 'date-fns'


export default {
  name: 'TracsClusterScreen',

    data: () => ({
      item: '',
      url2myMap:'/userdir/garmin/heatmap.html',
      abDatum: new Date('2023-11-16'),
      abDatumString: '',
      bisDatum: new Date(),
      bisDatumString: '',
      isMenuOpen: ref(false),
      isMenuOpenBisDatum: ref(false),

      activityID: 32,
    }),
      computed: {
       ...mapGetters({ geoheatmaps: 'stateGeoheatmaps'}),
       computedabDatumFormattedDatefns () {

            //tut let abDatumFormatiert = format(this.abDatum, 'EEEE, MMMM do yyyy')
            let abDatumFormatiert = format(this.abDatum, 'yyyy-MM-dd')
            //console.log('>>formatiert>>abdatum:',abDatumFormatiert)
            return abDatumFormatiert
       },
       computedbisDatumFormattedDatefns () {

            //tut let abDatumFormatiert = format(this.abDatum, 'EEEE, MMMM do yyyy')
            let bisDatumFormatiert = format(this.bisDatum, 'yyyy-MM-dd')
            //console.log('>>formatiert>>abdatum:',abDatumFormatiert)
            return bisDatumFormatiert
       },
      },
    mounted() {
        //Abdatum mit Gestern initialsieren


        const heute = new Date()
        const yesterday = new Date(heute)
        yesterday.setDate(yesterday.getDate() - 40)
        console.log('today:',heute )
        console.log('gestern:',yesterday )

        this.abDatum = yesterday

        var abDatumString = new Date(this.abDatum.getTime() - (this.abDatum.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
        var bisDatumString = new Date(this.bisDatum.getTime() - (this.bisDatum.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];

        console.log('Bin CLUSTER mounted:')

        const TracForm = new FormData();
        TracForm.append('abDatum', abDatumString);
        TracForm.append('bisDatum', bisDatumString);

        this.$store.dispatch('getGeoheatmap',TracForm).then(()=>{
            console.log("Got some data, now lets show something in this component",this.tracks)
            //refresh map
            document.getElementById('theFrame').src += '';

          }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
          })



    },
    updated() {
    // Fired every second, should always be true
    //console.log('updated DOM rerender')

    },

  methods: {
  ...mapActions(['getTracks']),
    updateMap() {
        //this.abDatum = new Date(this.abDatumString)
        console.log('update map with this abDatum:',this.abDatum)
        console.log('update map with this bisDatum:',this.bisDatum)
        var abDatumString = new Date(this.abDatum.getTime() - (this.abDatum.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
        var bisDatumString = new Date(this.bisDatum.getTime() - (this.bisDatum.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
        //this.$router.push({name: 'Tracks', params: {aktivitaten_activityId: row.item.aktivitaten_activityId}})

        const TracForm = new FormData();
        TracForm.append('abDatum', abDatumString);
        TracForm.append('bisDatum', bisDatumString);
        console.log('Aufruf Heatmapmit ab und Bis Datum',abDatumString,bisDatumString)
        this.$store.dispatch('getGeoheatmap',TracForm).then(()=>{
            console.log("Got some data, now lets show something in this component",this.tracks)
            //refresh map
            document.getElementById('theFrame').src += '';

          }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
          })

    },




  }
}
</script>

