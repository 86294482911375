<template>
  <h1>About Page</h1>
</template>


<script>

  export default {
    name: 'AboutScreen',
    //https://medium.com/js-dojo/how-to-implement-route-guard-in-vue-js-9929c93a13db

    data () {
      return {
        TargetEnv: process.env.VUE_APP_SERVER,
        variable1: process.env.VUE_APP_NOT_SECRET_CODE,
        array_BilderToAssembly: []
      }
    },
    mounted: function () {
      console.log('Bin in ABout with ID...with id:',localStorage.getItem('LoggedUser'))
      console.log('isAuthenticated',this.$store.getters.isAuthenticated)



        if(this.$store.getters.isAuthenticated)
         {
          console.log('##Allowed##'); // allow to enter route
          console.log('stateUser',this.$store.getters.stateUser.user_username)
         }
         else
         {
          console.log('!!!!!!not allowed!! GoTo LogIn'); // go to '/login';
         }


    },

    methods: {

      createCategory: function (schalter) {
        console.log('Bin createCategory',schalter)
        var neueKategorie = ['X','???',1,500]
        this.array_alleKategorien.unshift(neueKategorie)
      },

      getEnv: function (name) {
        console.log('Bin getEnv',name)
        //return window?.configs?.[name] || process.env[name]

      },

     },

  }
</script>