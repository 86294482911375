import { createWebHistory, createRouter } from "vue-router";

//import Home from "@/components/HelloWorld.vue";
import HomeScreen from '@/components/HomeScreen.vue';
import RegisterScreen from '@/components/RegisterScreen.vue';
import LoginScreen from '@/components/LoginScreen.vue';
import DashboardScreen from '@/components/DashboardScreen.vue';
import ProfileScreen from '@/components/ProfileScreen.vue';
import AboutScreen from "@/components/AboutScreen.vue";
import SplashScreen from "@/components/SplashScreen.vue";
import NotFound from "@/components/NotFound.vue";
import FastApi from "@/components/FastApi.vue";
import SystemScreen from '@/components/SystemScreen';
import Settings from '@/components/SettingsScreen';
import Charts from '@/components/ChartsScreen';
import Analyzer from '@/components/AnalyzerScreen';
import Calendar from '@/components/CalendarScreen';
import StocksScreen from '@/components/StocksScreen';
import AlbenScreen from '@/components/AlbenScreen';
import RankingScreen from '@/components/RankingScreen';
import ArticlesScreen from '@/components/ArticlesScreen';
import AktivitiesScreen from '@/components/ActivitiesScreen';
import ImageUploadScreen from '@/components/ImageUploadScreen';
import TrainObjectsScreen from '@/components/TrainObjectsScreen';
import TracksScreen from '@/components/TracksScreen';
import TracksClusterScreen from '@/components/TracksClusterScreen';
import TransactionScreen from '@/components/TransactionScreen';

import store from '@/store';

const routes = [
    {
    path: "/",
    name: "Spash",
    component: SplashScreen,
    },
//    {
//    path: "/splash",
//    name: "Splash",
//    component: SplashScreen,
//    },
    {
    path: "/fastapi",
    name: "FastApi",
    component: FastApi,
    },
    {
    path: "/about",
    name: "About",
    component: AboutScreen,
    },
    {
    path: "/home",
    name: "Home",
    component: HomeScreen,
    },
    {
    path: '/register',
    name: 'Register',
    component: RegisterScreen,
    },
    {
    path: '/login',
    name: 'Login',
    component: LoginScreen,
    },
    {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardScreen,
    meta: {requiresAuth: true},
    },
    {
    path: '/stocks',
    name: 'Stocks',
    component: StocksScreen,
    meta: {requiresAuth: true},
    },
    {
    path: '/tracks2',
    name: "Tracks2",
    component: TracksScreen,
    },
    {
    path: '/trackscluster',
    name: "TracksCluster",
    component: TracksClusterScreen,
    },
    {
      path: '/tracks/:aktivitaten_activityId',
      name: 'Tracks',
      component: TracksScreen,
      meta: {requiresAuth: true},
    },
    {
    path: '/analyzer/:idstocks:stocks_name',
    name: 'Analyzer',
    component: Analyzer,
    meta: {requiresAuth: true},
    },
    {
    path: '/analyzer',
    name: 'Analyzer2',
    component: Analyzer,
    meta: {requiresAuth: true},
    },
    {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: {requiresAuth: true},
    },
    {
    path: '/activities',
    name: 'Activities',
    component: AktivitiesScreen,
    meta: {requiresAuth: true},
    },
    {
    path: '/transactions',
    name: 'Transactions',
    component: TransactionScreen,
    meta: {requiresAuth: true},
    },
    {
    path: '/transactions/:idstocks',
    name: 'TransactionsWithIdstocks',
    component: Analyzer,
    meta: {requiresAuth: true},
    },
    {
    path: '/alben',
    name: 'Alben',
    component: AlbenScreen,
    meta: {requiresAuth: true},
    },
    {
      path: '/imageupload',
      name: 'ImageUpload',
      component: ImageUploadScreen
    },
    {
    path: '/rankings',
    name: 'Rankings',
    component: RankingScreen,
    meta: {requiresAuth: true},
    },
    {
    path: '/articles',
    name: 'Articles',
    component: ArticlesScreen,
    meta: {requiresAuth: true},
    },
    {
    path: '/profile',
    name: 'Profile',
    component: ProfileScreen,
    meta: {requiresAuth: true},
    },
    {
      path: '/Settings',
      name: 'Settings',
      component: Settings,
      meta: {requiresAuth: true},
    },
    {
      path: '/System',
      name: 'System',
      component: SystemScreen,
      meta: {requiresAuth: true},
    },
    {
      path: '/Charts',
      name: 'Charts',
      component: Charts,
      meta: {requiresAuth: true},
    },
    {
      path: '/TrainObjects',
      name: 'TrainObjects',
      component: TrainObjectsScreen,
      meta: {requiresAuth: true},
    },
    {
      path: "/:catchAll(.*)",
      component: NotFound,
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

//verify if users is allowed in Navigation Guard
router.beforeEach((to, from, next) => {
  //console.log('CheckAuth')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      console.log('Auth ok')
      next();
      return;
    }
    console.log('!! Auth NOT ok')
    //zwischenspeichern der Ziel - Einstiegsseite oder auf die Startseite aus Settings?
    //wenn zwischenspeichern, dann in settings speichern und im Login dort abrufen und starten
    //obj.emitter.emit("Zielseite", { msg: to });
    next('/login');
  } else {
    next();
  }
});


export default router;