<template>
  <v-app>

  <v-data-table
            :headers="headers"
            :items="settings"
            :options="options2"
  >
      <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Settings</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="props"
            >
              New Item
            </v-btn>
          </template>

      <v-dialog v-model="dialog" max-width="500px">



              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>

                        <v-text-field v-model="editedItem.settings_key" label="settings_key" readonly></v-text-field>
                        <v-select
                          v-show='editedItem.settings_key==="AssembliesProSeite"'
                          label="pro Seite"
                          id="comboProSeite"
                          :items=items_pagenumber
                          v-model=editedItem.settings_value
                        ></v-select>
                        <v-select
                          v-show='editedItem.settings_key==="Startseite"'
                          label="path (e.g. /charts)"
                          :items=items_paths
                          v-model=editedItem.settings_value
                        ></v-select>

                        <v-text-field
                          v-show='editedItem.settings_key!="Startseite"'
                          label="Anzahl,.."
                          v-model=editedItem.settings_value
                        ></v-text-field>

                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
      </v-dialog>



        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        size="small"
        class="me-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        size="small"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>


  </v-data-table>



  </v-app>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'SettingsScreen',

    data: () => ({
      item: '',
      passwort: '',
      user: '',
      ShowUserError: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        idSettings: 0,
        settings_key: '',
        settings_value: '',
      },
      items_pagenumber: ['10', '20', '50', '100'],
      items_paths: ['/vue', '/analyzer','/activities','/articles','/calendar','/charts', '/dashboard','/historie','/stocks','/rankings','/tracklist','/chat','/trainObjects','/users'],
      options2: {},
      array_allSettings: [],
      headers: [
        {
          text: 'settings_key',
          align: 'left',
          sortable: true,
          value: 'settings_key'
        },
        { text: 'settings_value', value: 'settings_value'},
        { text: 'Actions', value: 'actions'},
      ],
      pagination: {
        rowsPerPage: -1
      },

    }),
    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    computed: {
      ...mapGetters({ settings: 'stateSettings'}),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      }
    },
    mounted: function () {
      console.log('Dispatch settings')

     //const newsettings = this.$store.dispatch('getSettings')
//             this.$store.dispatch('getSettings').then((response) => {
//            console.log('Settings gespeichert:', response)
//            });
      //let res = await this.$store.dispatch('getSettings')

      //const newsettings = await this.$store.dispatch('getSettings')
      //await this.$store.dispatch('getSettings')

      this.$store.dispatch('getSettings').then(response => {
            console.log("Got some data, now lets show something in this component",response)

                  for (let i = 0; i < this.settings.length; i++) {
                    //console.log('Settings',this.settings[i])
                    this.array_allSettings.push(this.settings[i])
                  }

        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })


//      for (let i = 0; i < this.settings.length; i++) {
//        console.log('Settings',this.settings[i])
//        this.array_allSettings.push(this.settings[i])
//      }

    },

    created: function () {
      console.log('Dispatch settings')



    },


  methods: {
  ...mapActions(['updateSetting']),
        editItem (item) {
          //this.editedIndex = this.array_allSettings[0].indexOf(item)
          //this.editedIndex = this.settings[0].indexOf(item)
          this.editedIndex = this.settings.indexOf(item)
          console.log('index',this.editedIndex)
          this.editedItem = Object.assign({}, item)
          this.dialog = true
        },
        close () {
          this.dialog = false
//          setTimeout(() => {
//            this.editedItem = Object.assign({}, this.defaultItem)
//            this.editedIndex = -1
//          }, 300)
        },

        save (){
          console.log('erst mal ein Setting manuell speichern',this.editedIndex,this.settings)
          console.log('edidetitem7:',this.editedItem.settings_value, this.editedItem.settings_key)




        var neuesSetting = {
            "c_group": 0,
            "c_owner": 0,
            "c_unixperms": 0,
              "settings_key": this.editedItem.settings_key,
              "settings_value": this.editedItem.settings_value,
            "user_iduser": 0,
            }


        //const Settings = {'settings_value': this.editedItem.settings_value,'settings_key': this.editedItem.settings_key}
        var obj = this

        this.$store.dispatch('createSetting',neuesSetting).then(()=>{
            console.log("Got some data, now lets show something in this component",obj.settings[obj.editedIndex])
            console.log("settings",obj.settings)
            console.log("settings",obj.editedItem,obj.editedItem.settings_value)
            obj.settings[obj.editedIndex].settings_value = obj.editedItem.settings_value


          }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
          })


//        this.$store.dispatch('updateSetting',Settings).then(()=>{
//            console.log("Got some data, now lets show something in this component",obj.settings[obj.editedIndex])
//            console.log("settings",obj.settings)
//            console.log("settings",obj.editedItem,obj.editedItem.settings_value)
//            obj.settings[obj.editedIndex].settings_value = obj.editedItem.settings_value
//
//
//          }, error => {
//            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
//          })



//          if (this.editedIndex > -1) {
//            Object.assign(this.array_allSettings[0][this.editedIndex], this.editedItem)
//            //console.log('Wuupi:',this.array_allSettings[0][this.editedIndex])
//            this.editedItem.idSettings = this.array_allSettings[0][this.editedIndex].idSettings
//            this.saveSettings(this.editedItem)
//          } else {
//            this.array_allSettings[0].push(this.editedItem)
//          }
          this.close()
        },

        clearCache () {
          // make sure new API is supported
          if (!window.caches) {
              console.log('Clear Service Worker NOT SUPPORTED by browser')
              return;
          }

          caches.keys().then(function(names) {
              console.log('Clear Service Worker cache')
              for (var index = 0; index < names.length; index++) {
                  var name = names[index];
                  caches.delete(name);
              }
          });

        },

    logoutUser: function () {
          console.log('logoutUser:')

             // Sending and receiving data in JSON format using POST method
              //

              var url = "/fileJSON/logoutUser";
              //var data = JSON.stringify({
              //    "user": user,
              //})

            var request = new XMLHttpRequest();

            var obj = this //this inside of xhr.onreadystatechange does not refer to the instance of Obj. you need to capture this outside of the function in a local variable an then use that inside o

            function onLoadListener() {
              var data = JSON.parse(this.responseText);
              //console.log('Login:',data);
              var json = JSON.parse(data)
              var result = json[0].result
              console.log('result:',result)
              window.Event.$emit('userLoggedout')
              if(result == 'gueltiger User'){
                obj.ShowUserError = true
                console.log('Load Assemblies')
                }
              else{
                //console.log('invalid USer')
                obj.ShowUserError = false
              }
            }

            function onErrorListener(err) {
              console.log('XHR Error :', err);
            }

            request.onload = onLoadListener;
            request.onerror = onErrorListener;
            request.open("POST", url, true);
            request.setRequestHeader("Content-type", "application/json");
            request.send()
          },

  }
}
</script>




<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>


