<template>

    <v-card>
        <v-card-title>Prognose für Monat {{PrognoseDistanzMonat}} km</v-card-title>
            <v-card-text>
              <v-row
              >
                <div class="grey--text ms-4">
                  Kilometer bis Heute Monat: {{DistanzAktuellerMonat}} km
                </div>
              </v-row>

              <v-row>
                <div class="grey--text ms-4">
                  Schnitt pro Tag: {{Math.ceil((parseFloat(SchnittProTag))*100)/100}} km
                </div>
              </v-row>

              <v-row>
                <div class="grey--text ms-4">
                  Letzter Bike Tag: {{ DatumDerLetztenAktivity  }} - Resttage: {{DeltaTageBisEndeMonat}}
                </div>
              </v-row>

              <v-row>
                <div class="grey--text ms-4">
                  Last Garmin Connect Update: {{LastGarminLogin}}
                </div>
              </v-row>


            </v-card-text>

    </v-card>


          <v-calendar
            ref="calendar"
            color="primary"
            v-model="value"
            :weekdays="weekday"
            :view-mode="type"
            :events="events"
            @click:event="showEvent"
          ></v-calendar>




</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Chart, registerables  } from "chart.js";
import { useDate } from 'vuetify'
import _ from 'lodash'
//import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  name: 'CalendarScreen',
   data () {
    return {
        DistanzVorletzteWoche: 0,
        DistanzAktuellerMonat: 0,
        PrognoseDistanzMonat: 0,
        DistanzProTag: 0,
        SchnittProTag: 0,
        DatumDerLetztenAktivity : 0,
        DeltaTageBisEndeMonat : 0,
        LastGarminLogin: '1998',
        LastGarminLoginUhrzeit: '0:00',
        array_allActivities:[],
      type: 'month',
      types: ['month', 'week', 'day'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { title: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { title: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { title: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { title: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: [new Date()],
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      titles: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],


    }
  },
      watch: {
      model (val, prev) {
        if (val.length === prev.length) return

        this.model = val.map(v => {
          if (typeof v === 'string') {
            v = {
              text: v,
              color: this.colors[this.nonce - 1],
            }

            this.items.push(v)

            this.nonce++
          }

          return v
        })
      },
    },
  created: function() {
    console.log('-->>Bin in Calendar created')


  },
  computed: {
    ...mapGetters({ activities: 'stateActivities',settings: 'stateSettings'}),
  },
  mounted: function () {
      console.log('calendar mounted..')
      const adapter = useDate()
      this.getEvents({ start: adapter.startOfDay(adapter.startOfMonth(new Date())), end: adapter.endOfDay(adapter.endOfMonth(new Date())) })

      var obj = this

      const events2 = []

        var aktivytycolors = {};

        aktivytycolors["cycling"] = 'green lighten-2'
        aktivytycolors["cross_country_skiing_ws"] = 'blue darken-1'
        aktivytycolors["indoor_cycling"] = 'deep-purple'
        aktivytycolors["resort_skiing_snowboarding_ws"] = 'cyan darken-1'
        aktivytycolors["other"] = 'orange'
        aktivytycolors["running"] = 'cyan'
        aktivytycolors["boating"] = 'grey darken-1'
        aktivytycolors["mountain_biking"] = 'light-blue darken-1'
        aktivytycolors["road_biking"] = 'green lighten-3'
        aktivytycolors["virtual_ride"] = 'orange lighten-3'



      this.$store.dispatch('getActivities').then(()=>{
        console.log("--Got some activities data--")
        obj.activities.forEach(function(element) {
            //console.log('#',element.aktivitaeten_startTimeGMT)
            //'2023-12-30T13:09:25Z'
            const first = new Date(element.aktivitaeten_startTimeGMT)
            //console.log('first:',first)
            const second = new Date(first.getTime() + element.aktivitaeten_duration * 1000)
                  events2.push({
                    title: element.aktivitaeten_activityName,
                    start: first,
                    end: second,
                    color: aktivytycolors[element.aktivitaeten_activityType],
                    //allDay: true,
                  })


                })
                obj.events = events2
                obj.createPrognose()
                //obj.LastGarminLogin = obj.$store.getters.getSettingsByKey('LastGarminLogin')
                return
            })

      this.$store.dispatch('getSettings').then(()=>{
        console.log("--Got some activities data--")
                _.forEach(obj.settings,item => {

                    //console.log('setting',item.settings_key)
                    if(item.settings_key == 'LastGarminLogin'){
                        //console.log('LastGarminLogin',item.settings_value)
                        obj.LastGarminLogin = new Date(item.settings_value)
                        obj.LastGarminLoginUhrzeit = obj.LastGarminLogin.toLocaleTimeString()
                        obj.LastGarminLogin = obj.LastGarminLogin.toLocaleString()

                    }


                })

                //obj.LastGarminLogin = obj.$store.getters.getSettingsByKey('LastGarminLogin')
                return
            })





      },
  methods: {
    ...mapActions(['createStock']),
    onChangeCombo3(entry){
        console.log('So this is your favorite number: ',entry);
    },
      createPrognose () {
        console.log('Prognose..');
        var obj = this
        var orderedByZeitraum =[]
            Date.prototype.getWeek = function() {
                var dt = new Date(this.getFullYear(),0,1);
                var kalenderwoche = Math.ceil((((this - dt) / 86400000) + dt.getDay()+1)/7)
                var JahrUndWoche = (dt.getFullYear()-2000).toString() +'_' + kalenderwoche
                //console.log('kw:',JahrUndWoche +'_' + kalenderwoche)
                //return Math.ceil((((this - dt) / 86400000) + dt.getDay()+1)/7);
                return JahrUndWoche;
            };
        orderedByZeitraum = _.groupBy(obj.activities, function(element) {
            const startTimeGMT = new Date(element.aktivitaeten_startTimeGMT)
            return startTimeGMT.getWeek()
        //return element.aktivitaeten_startTimeGMT.getDay()
        });

        console.log('groupedByWeek:',orderedByZeitraum)
                        //drueber loopen und summieren
        //var index = 0


        _.forEach(orderedByZeitraum,item2 => {
            //console.log('item2',Object.keys(orderedByZeitraum)[index],item2)

                    //var index2 = 0
                    for (var key in obj.e3_Durchschnitt) {
                        var value = obj.e3_Durchschnitt[key];
                        //console.log('>>>Add to chart selected kategore:',value.dbname)


                        //var CaloriesProZeitraum = _.sumBy(item2, 'aktivitaeten_calories');
                        var SummeProZeitraum = _.sumBy(item2, value.dbname);
                        if(value.dbname==='aktivitaeten_averageSpeed'){
                            SummeProZeitraum = SummeProZeitraum *3.6 //Umrechnenin km/h
                        }
                        if(value.dbname==='aktivitaeten_distance'){
                            SummeProZeitraum = SummeProZeitraum / 1000 //Umrechnenin km
                        }

                        //Dia Anzahl de Teilers muss ich ueber den -

                        //getkeyName mit Index und dann die Anzahl ermitteln
                        //var keyname = Object.keys(orderedByZeitraum)[index]

                        //var DurchschnittProZeitraum = SummeProZeitraum / orderedByZeitraum[keyname].length
                        //var DurchschnittProZeitraum
                        //DurchschnittProZeitraum = SummeProZeitraum / 7 //Woche hat 7 Tage
                        //console.log('DurchschnittProZeitraum',value.dbname, DurchschnittProZeitraum)

                        //index2 += 1

                    }


                    //obj.line2Chart.data.labels.push(Object.keys(orderedByZeitraum)[index])
            //index +=1
        })
                                //neuer durchschnitt vorletzte Woche
                //index = 0
                var vorletzeWoche_Array = orderedByZeitraum[Object.keys(orderedByZeitraum)[Object.keys(orderedByZeitraum).length - 2]]
                var DistanzProWoche = 0
                console.log('Vorletzte Woche:', vorletzeWoche_Array)
                _.forEach(vorletzeWoche_Array,item3 => {
                    //console.log('item3',item3,item3.aktivitaeten_distance)
                    DistanzProWoche += item3.aktivitaeten_distance

                })
                DistanzProWoche = (DistanzProWoche / 1000).toFixed(1) //Umrechnenin km
                console.log('NEU>DistanzProWoche',DistanzProWoche)
                //obj.DistanzVorletzteWoche = DistanzProWoche.toFixed(2)
                //obj.DistanzProTag = (DistanzProWoche / 7)

                var letzeWoche_Array = orderedByZeitraum[Object.keys(orderedByZeitraum)[0]]
                console.log('letzte Woche:',letzeWoche_Array )
                console.log('Object keys:',Object.keys(orderedByZeitraum)[0])
//                _.forEach(letzeWoche_Array,item3 => {
//                    //console.log('item3_2',item3,item3.aktivitaeten_startTimeGMT)
//                    obj.DatumDerLetztenAktivity = new Date (item3.aktivitaeten_startTimeGMT)
//                })

                obj.DatumDerLetztenAktivity = new Date (letzeWoche_Array [0].aktivitaeten_startTimeGMT)
                obj.DatumDerLetztenAktivity = obj.DatumDerLetztenAktivity.toLocaleDateString()
                var today = new Date();
                var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
                console.log('>>>Last Day of month',lastDayOfMonth)
                const diffInMs   = new Date(lastDayOfMonth) - today
                obj.DeltaTageBisEndeMonat = (diffInMs / (1000 * 60 * 60 * 24)).toFixed(0);
                console.log('>>>Delta Days',obj.DeltaTageBisEndeMonat,obj.DatumDerLetztenAktivity,lastDayOfMonth)

                 obj.renderComponent = true  //wegen asynchronem update das rendern verzoegern
                      //obj.updateRange() wie kann ich start ende vorgeben?

                    //jetzt noch die Ist Distanz im aktuellen Monat berechnen
                    Date.prototype.getYearMonth = function() {
                          var JahrUndMonat = this.getFullYear().toString() +'_' + (this.getMonth()+1).toString()
                          //console.log('JahrUndMonat:',JahrUndMonat)
                          return JahrUndMonat;
                    };
                      var orderedByZeitraumMonat = _.groupBy(obj.activities, function(element) {
                        //console.log('elem:',element.aktivitaeten_startTimeGMT)
                        const TimeGMT = new Date(element.aktivitaeten_startTimeGMT)
                        return TimeGMT.getYearMonth()
                        });
                    console.log('groupedByYearMonth:',orderedByZeitraumMonat)

                    var DistanzProZeitraum = 0

                    var letzerMonat_Array = orderedByZeitraumMonat[Object.keys(orderedByZeitraumMonat)[0]]
                    console.log('letzte Woche:',letzerMonat_Array )
                    console.log('Object keys:',Object.keys(orderedByZeitraumMonat)[0])

                    _.forEach(letzerMonat_Array,item2 => {
                            //console.log('item2:',item2)
                            DistanzProZeitraum += item2.aktivitaeten_distance


                          })
                    DistanzProZeitraum = (DistanzProZeitraum / 1000).toFixed(1) //Umrechnenin km

                    obj.DistanzAktuellerMonat = DistanzProZeitraum
                    console.log('Heute als Tag:',today.getDate())
                    obj.SchnittProTag = obj.DistanzAktuellerMonat / today.getDate()
                    //console.log('Distnaz aktueller Monat:',DistanzProZeitraum)

                    //Berechnung Prognose
                    obj.PrognoseDistanzMonat = (obj.SchnittProTag * obj.DeltaTageBisEndeMonat + parseFloat(obj.DistanzAktuellerMonat) ).toFixed(0)





      },
      getEvents ({ start, end }) {

        var obj = this
        const events = []

        const min = start
        const max = end
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)

        for (let i = 0; i < eventCount; i++) {
          const allDay = obj.rnd(0, 3) === 0
          const firstTimestamp = obj.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = obj.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)

          events.push({
            title: obj.titles[obj.rnd(0, obj.titles.length - 1)],
            start: first,
            end: second,
            color: obj.colors[obj.rnd(0, obj.colors.length - 1)],
            allDay: !allDay,
          })
        }

        //obj.events = events
      },
      getEventColor (event) {
        return event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },



  },
};
</script>