import axios from 'axios';

const state = {
  transactions: null,
  transaction: null
};

const getters = {
  stateTransactions: state => state.transactions,
  stateTransaction: state => state.transaction,
};

const actions = {
  async createTransaction({commit}, payload) {
    //await axios.post('transaction', transaction);
    //await dispatch('getTransactions');

    //console.log('--payload:',payload)
    const asString2 = new URLSearchParams(payload).toString();
    //console.log('--Post:',asString2)

    const url = 'transaction?'+asString2
    const {data} = await axios.post(url)

    commit('setTransaction', data);
  },
  //liefert nur zu angemeldetem User
  async getTransactions({commit}, stockid) {
    console.log('getTransactions:',stockid)

    //let {data} = await axios.get(`transactions?stock_id=${stockid}`);
    if(stockid){
        let {data} = await axios.get(`transactions?stock_id=${stockid}`)
        commit('setTransactions', data);
        }
    else{
        let {data} = await axios.get(`transactions`)
        commit('setTransactions', data);
    }
    //let {data} = await axios.get('transactions?stock_id=163')
    //commit('setTransactions', data);
  },
  async getTransactionsOLD({commit}) {
    console.log('<-<-<-<-<>>>transactions.js: getTransactions:')
    let {data} = await axios.get('transactions');
    commit('setTransactions', data);
  },
  //liefert zu allen Usern
  async postTransactions({commit}) {
    let {data} = await axios.post('transactions');
    commit('setTransactions', data);
    },
  async setTransactions({commit}, id) {
    console.log('setTransaction:',id)

    let {data} = await axios.get(`transaction/${id}`);
    commit('setTransaction', data);
  },
  // eslint-disable-next-line no-empty-pattern
  async updateTransaction({}, transaction) {
    await axios.patch(`transaction/${transaction.id}`, transaction.form);
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteTransaction({}, id) {
    await axios.delete(`transaction/${id}`);
  }
};

const mutations = {
  setTransactions(state, transactions){
    state.transactions = transactions;
  },
  setTransaction(state, transaction){
    state.transaction = transaction;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};