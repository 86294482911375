import axios from 'axios';

const state = {
  stockhistories: null,
  stockhistory: null,
  stocksignals: null,
  preisdeltas: null,
};

const getters = {
  stateStockhistories: state => state.stockhistories,
  statePreisdeltas: state => state.preisdeltas,
  stateStockhistory: state => state.stockhistory,
  stateStocksignals: state => state.stocksignals,
};

const actions = {
  async createStockhistory({dispatch}, stockhistory) {
    await axios.post('stockhistories', stockhistory);
    await dispatch('getStockhistories');
  },
  async getStockhistories({commit},payload) {
    //let {data} = await axios.get('stockhistories'); //so waere es sauber benammt
    //console.log('## getStockhistories>>ausgewaehltestocks',payload)
    let {data} = await axios.post('gettrend',payload); //wegen Kompatibilät zu rateit2
    commit('setStockhistories', data);
  },
  async getPreisdeltas({commit},payload) {
    //let {data} = await axios.get('Preisdeltas'); //so waere es sauber benammt
    //console.log('## in store getter>>ausgewaehltestocks',payload)
    let {data} = await axios.post('deltapreis',payload); //wegen Kompatibilät zu rateit2
    commit('setPreisdeltas', data);
  },
  async getSignale({commit},payload) {
    //console.log('## in store getter getSignale >>ausgewaehltestocks',payload)
    //let {data} = await axios.post('signale/43',payload);
    let {data} = await axios.post('signale',payload);
    commit('setSignals', data);
  },

  async viewStockhistory({commit}, id) {
    console.log('stockhistories.js: viewStockhistory:',id)

    let {data} = await axios.get(`stockshistory/${id}`);
    commit('setStockhistories', data);
  },
  // eslint-disable-next-line no-empty-pattern
  async updateStockhistory({}, stockhistory) {
    await axios.patch(`stockhistory/${stockhistory.id}`, stockhistory.form);
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteStockhistory({}, id) {
    await axios.delete(`stockhistory/${id}`);
  }
};

const mutations = {
  setStockhistories(state, stockhistories){
    state.stockhistories = stockhistories;
  },
  setPreisdeltas(state, preisdeltas){
    state.preisdeltas = preisdeltas;
  },
  setStockhistory(state, stockhistory){
    state.stockhistory = stockhistory;
  },

  setSignals(state, stocksignals){
    state.stocksignals = stocksignals;
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};