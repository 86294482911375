<template>

    meine Alben - wenn nur 20 kommen ist in API zum test limit gesetzt!

    <v-row
          v-for="n in gruppierteBilder.length"
          :key="n"
          class="d-none d-sm-flex"
        >

        <v-col
          v-for="bild in gruppierteBilder[n-1]"
          :key="bild"
          class="d-flex child-flex"
          cols="3"
        >

            <div @click="showImage(bild,bild.assembly_idassembly_id)" :style="{ backgroundImage : 'url(' + encodeURI(bild.Icon_URL) + ')', 'background-size': '200px',  width: '100%', height: '200px'}">
                    {{bild.assembly_Benennung}}<br>
                    {{bild.idBilder}}<br>
                    {{bild.assembly_idassembly_id}}<br>
                    {{bild.tag}}<br>
                    <div v-if="bild.bilder_UploadDate">
                            {{bild.bilder_UploadDate.substring(0, 10)}}
                    </div>
                    <div v-else>
                            kein Datum
                    </div>

            </div>

        </v-col>


    </v-row>


    <v-row
          v-for="n in gruppierteBilderIn2erGruppen.length"
          :key="n"
          class="d-flex d-sm-none"
        >




        <div class="d-flex justify-space-evenly">
          <v-sheet
            v-for="bild in gruppierteBilderIn2erGruppen[n]"
            :key="bild"

          >

           <div @click="showImage(bild)" :style="{ backgroundImage : 'url(' + encodeURI(bild.Icon_URL) + ')', 'background-size': '400px',  height: '200px'}">
                {{bild.assembly_Benennung}}<br>
                {{bild.idBilder}}<br>
                <div v-if="bild.bilder_UploadDate">
                        {{bild.bilder_UploadDate.substring(0, 10)}}
                </div>
                <div v-else>
                        kein Datum
                </div>

            </div>


          </v-sheet>
        </div>



    </v-row>

    <v-pagination
      v-model="page"
      :length="page_numberofpages"
      @update:model-value="springeZuSeite()"
      class="my-4"
    ></v-pagination>


    <canvas width="1000" height="600" id="myCanvas" ref="myCanvas"  style="border: 2px dashed grey;">
          Your browser does not support the HTML5 canvas tag.</canvas>

            <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition" :overlay=false>

              <v-card
              :loading= "uploadstarted"
              >
                <v-toolbar dark color="primary">
                  <v-btn icon @click="dialog = false" dark>
                    <v-icon icon="mdi-close"></v-icon>
                  </v-btn>
                  <v-toolbar-title>Vollbild</v-toolbar-title>

                </v-toolbar>


                <v-alert
                v-if = "uploadsuccess"
                density="compact"
                type="warning"
                title="Success"
                text="Bild was uploaded!"
                ></v-alert>

                <v-alert
                v-if = "uploaderror"
                density="compact"
                type="warning"
                title="uploaderror"
                text="Bild was NOT UPLOADED!"
                ></v-alert>



                <v-text-field
                    label="assembly_Benennung"
                    v-model="aktuellesBild.assembly_Benennung"
                ></v-text-field>
                <v-text-field
                    label="Bilder_Benennung"
                    v-model="aktuellesBild.Bilder_Benennung"
                ></v-text-field>
                <v-text-field
                    label="bilder_UploadDate"
                    v-model="aktuellesBild.bilder_UploadDate"
                ></v-text-field>
                <v-card-actions>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on">

                               <v-btn density="default" @click="saveMasterdata('schalter')" icon="mdi-content-save"></v-btn>
                            </v-btn>
                          </template>
                          <span>Bild Änderungen speichern</span>
                        </v-tooltip>



                               <v-file-input
                                label="add image to Album"
                                variant="filled"
                                prepend-icon="mdi-camera"
                                @change="onFileChange"
                              ></v-file-input>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on">
                               <v-btn @click="add2Wishlist('schalter')" icon="mdi-lightbulb-on-outline"></v-btn>
                            </v-btn>
                          </template>
                          <span>Bild zur Wunschliste hinzufügen</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on">
                               <v-btn @click="rotate90Bild('schalter')" icon="mdi-crop-rotate"></v-btn>
                            </v-btn>
                          </template>
                          <span>Bild um 90 Grad drehen</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on">
                               <v-btn @click="openLink('schalter')" icon="mdi-open-in-app"></v-btn>
                            </v-btn>
                          </template>
                          <span>Link öffnen</span>
                        </v-tooltip>


                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on">
                               <v-btn @click="browseFiles('schalter')" icon="mdi-file-upload-outline"></v-btn>
                            </v-btn>
                          </template>
                          <span>Dateien ansehen oder hinzufügen</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on">
                               <v-btn @click="deleteBild('schalter')" icon="mdi-delete"></v-btn>
                            </v-btn>
                          </template>
                          <span>Bild loeschen</span>
                        </v-tooltip>
                </v-card-actions>

                 <v-img
                    :src='aktuellesBild.Bild_URL'

                  ></v-img>


              </v-card>

            </v-dialog>







</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash'
export default {
  name: 'AlbenScreen',
  data() {
    return {
      dialog: false,
      uploadsuccess: false,
      uploaderror: false,
      aktuellesBild: 'not clicked',
      AssemblyID: 0,
      form: {
        title: '',
        content: '',
      },
      isHovering: true,
      page: 1, //Aktuelle Seite
      page_numberofitems: 10, //Anzahl die pro Seite angezeigt wird
      page_numberofpages: 50, //Anzahl Seiten,Nicht ueber die DB ermittel, das seeeehr lange dauert https://www.cybertec-postgresql.com/en/pagination-problem-total-result-count/
      page_offset: 0, //Aktuelle Seite
    };
  },
  created: function() {
    console.log('Bin in Alben')
    //return this.$store.dispatch('getBilder');

    var obj = this

    this.$store.dispatch('getSettings').then(()=>{
        console.log("--Got some Settings--")
                _.forEach(obj.settings,item => {

                    //console.log('setting',item.settings_key)
                    if(item.settings_key == 'AssembliesProSeite'){
                        //console.log('LastGarminLogin',item.settings_value)
                        obj.page_numberofitems = item.settings_value
                        const BilderForm = new FormData();
                        BilderForm.append('offset', obj.page_offset);
                        BilderForm.append('anzahl_Bilder', obj.page_numberofitems);

                        //const downsamplefaktor = 2
                        this.$store.dispatch('getBilder',BilderForm).then(()=>{
                            console.log("Got some data, now lets show something in this component",this.tracks)


                        }, error => {
                            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
                        })


                    }


                })

                //obj.LastGarminLogin = obj.$store.getters.getSettingsByKey('LastGarminLogin')
                return
            })


  },
  computed: {
    //...mapGetters({ bilder: 'stateBilder'}),
    ...mapGetters({ bilder: 'stateBilder',settings: 'stateSettings'}),

    gruppierteBilder() {
        const result = []
        for (let i = 0; i < this.bilder.length; i += 3)
            result.push(this.bilder.slice(i, i + 3))
        //console.log('gruppiert',result)
        return result
    },
    gruppierteBilderIn2erGruppen() {
        const result = []
        for (let i = 0; i < this.bilder.length; i += 2)
            result.push(this.bilder.slice(i, i + 2))
        //console.log('gruppiert',result)
        return result
    }
  },
  methods: {
    ...mapActions(['createNote']),
    async submit() {
      console.log('AlbenScreen')
    },
    springeZuSeite () {
        console.log('Springe zu Seite:', this.page)
        this.page_offset = (this.page_numberofitems * this.page) - this.page_numberofitems
        console.log('this.page_offset:', this.page_offset)
        const BilderForm = new FormData();
        BilderForm.append('offset', this.page_offset);
        BilderForm.append('anzahl_Bilder', this.page_numberofitems);

        //const downsamplefaktor = 2
        this.$store.dispatch('getBilder',BilderForm).then(()=>{
            console.log("Got some data, now lets show something in this component",this.tracks)


        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })

//        this.$root.global_page = this.page
//        this.page_offset = (this.$store.getters.getSettingsByKey('AssembliesProSeite') * this.page) - this.$store.getters.getSettingsByKey('AssembliesProSeite')
//        this.ansicht = this.$store.getters.getSettingsByKey('Ansicht')
//        this.getAssemblies('leseAssemblies')
      },
    async showImage(bild,assembly_idassembly) {
      console.log('showImage full screen',assembly_idassembly,bild)
      this.aktuellesBild = bild
      this.AssemblyID = assembly_idassembly
      this.dialog = true
    },
    onFileChange(e) {
      console.log('Bin in onFileChange')
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.uploadedimage = files[0]
      this.createImage(files[0]);
    },
    saveMasterdata(e) {
      console.log('Bin in saveMasterdata',this.aktuellesBild,e)
      var obj = this


        var formData = new FormData();
        formData.append('Bild_id', obj.aktuellesBild.idBilder);
        formData.append('Bilder_Benennung', obj.aktuellesBild.Bilder_Benennung);
      obj.$store.dispatch('updateBild',formData).then(response => {
        console.log("Got some data, now lets show something in this component",response)

    }, error => {
        console.error("Got nothing from server. Prompt user to check internet connection and try again",error)

    })

    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

          console.log('Bin in createImage')
          this.uploadstarted = true
          var ctx = this.$refs.myCanvas.getContext('2d')

      reader.onload = (e) => {
        vm.image = e.target.result;
        var dataURL = e.target.result

          image.onload = function() {
        //Skalierung ermitteln
        var MAX_WIDTH = 1000;
        var MAX_HEIGHT = 600;
        var width = image.width;
        var height = image.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
          }
        }
            ctx.width = image.width;
            ctx.height = image.height;
            ctx.drawImage(image, 0, 0,width,height);
            vm.addBild()
          };

          image.src = dataURL;

      };
      reader.readAsDataURL(file);
    },
    async addBild() {
      //console.log('AssemblyScreen: Async submit createAssembly')

      var obj = this
        if (navigator.geolocation) {
                await navigator.geolocation.getCurrentPosition(success);
        } else {
                console.log('Geolocation is not supported by this browser')
      }


      function success(position) {

            var latitude  = position.coords.latitude;
            var longitude = position.coords.longitude;
            var latitude_global = latitude
            var longitude_global = longitude


            //var image = obj.$refs.myCanvas.toDataURL("image/png");

            console.log('Paras:',latitude_global,longitude_global)


              //console.log('asi created:',obj.assemblies[0].idassembly,res)
              //console.log('neuesAssemblyObject',neuesAssemblyObject, neuesAssemblyObject.idassembly)

              //wenn erfolgreich dann ein Bild anlegen

            var formData = new FormData();
            formData.append("file",  obj.uploadedimage)
                //formData.append('assembly_idassembly_id', obj.assemblies[0].idassembly);
            formData.append('assembly_idassembly_id', obj.AssemblyID);
            formData.append('bilder_geo_longi_X', longitude)
            formData.append('bilder_geo_lati_Y', latitude)
            formData.append('Bilder_Benennung', " ")


                //this.$store.dispatch('getStockhistories',ausgewaehlteStockIDs)

            obj.$store.dispatch('createBildmitfiles',formData).then(response => {
                    console.log("Got some data, now lets show something in this component",response)
                    obj.uploadsuccess = true
                    obj.uploadstarted = false
                }, error => {
                    console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
                    obj.uploaderror = true
                    obj.uploadstarted = false
                })


          }//End funtion position success

    },

  },
};
</script>