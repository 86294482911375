import axios from 'axios';

const state = {
  unknowns: null,
  unknown: null
};

const getters = {
  stateUnknowns: state => state.unknowns,
  stateUnknown: state => state.unknown,
};

const actions = {

  //liefert nur zu angemeldetem User
  async getUnknowns({commit}) {
    let {data} = await axios.get('unknowns');
    //console.log('<-<-<-<-<>>>getUnknowns:',data)
    commit('setUnknowns', data);
  },

  async tagfaces({dispatch}, payload) {
    console.log('<-<-<-<-<>>>tagfaces:',payload)
    const asString2 = new URLSearchParams(payload).toString();
    const url = 'tagfaces?'+asString2
    let {data} = await axios.post(url);

    console.log('<-<-<-<-<>>>tagfaces:',data,dispatch)
    //commit('setUnknowns', data);
  },

};

const mutations = {
  setUnknowns(state, unknowns){
    state.unknowns= unknowns;
  },
  setUnknown(state, unknown){
    state.unknown = unknown;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};