import axios from 'axios';

const state = {
  activities: null,
  activity: null
};

const getters = {
  stateActivities: state => state.activities,
  stateActivity: state => state.activity,
};

const actions = {
  async createActivity({dispatch}, activity) {
    await axios.post('activities', activity);
    await dispatch('getActivities');
  },
  //liefert nur zu angemeldetem User
  async getActivities({commit}) {
    console.log('<-<-<-<-<>>>activities.js: getActivitiess:')
    let {data} = await axios.get('activities');
    commit('setActivities', data);
  },
  //liefert zu allen Usern
  async postActivities({commit}) {
    let {data} = await axios.post('activities');
    commit('setActivities', data);
    },
  async setActivities({commit}, id) {
    console.log('setActivities:',id)

    let {data} = await axios.get(`activity/${id}`);
    commit('setActivity', data);
  },
  // eslint-disable-next-line no-empty-pattern
  async updateActivity({}, activity) {
    await axios.patch(`activity/${activity.id}`, activity.form);
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteActivity({}, id) {
    await axios.delete(`activity/${id}`);
  }
};

const mutations = {
  setActivities(state, activities){
    state.activities = activities;
  },
  setActivitie(state, aktivity){
    state.aktivity = aktivity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};